import React, { useState } from 'react';
import PoolList from './PoolList';
import PoolProfile from './PoolProfile';
import AddPool from './AddPool';

const PoolsTab = ({ id }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [selectedPool, setSelectedPool] = useState();

    return (
        <>
            {activeTab === 0 && <PoolList id={id} setActiveTab={setActiveTab} setSelectedPool={setSelectedPool} />}
            {activeTab === 1 && <PoolProfile id={selectedPool} setActiveTab={setActiveTab} />}
            {activeTab === 2 && <AddPool id={id} setActiveTab={setActiveTab} />}
        </>
    );
};

export default PoolsTab;