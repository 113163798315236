import React from 'react';
import useColors from '../../hooks/useColors';

const Loader = ({ color, size }) => {
    const { primaryColor } = useColors();

    return (
        <svg className='loader-svg' width={size ?? '3.25rem'} viewBox="25 25 50 50">
            <circle className='loader-circle' stroke={color ?? primaryColor} r="20" cy="50" cx="50"></circle>
        </svg>
    );
};

export default Loader;