// LoggedOutRoutes.js
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from '../pages/auth/Login';

const routes = [
    { path: '/', element: <Login /> },
];

const LoggedOutRoutes = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (routes.filter(el => el.path === location.pathname).length === 0) {
            navigate('/');
        }
    }, [location.pathname, navigate]);

    return (
        <Routes>
            {routes?.map((route, index) => {
                return (
                    <Route key={index} path={route.path} element={route.element} />
                );
            })}
        </Routes>
    );
};

export default LoggedOutRoutes;
