import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAnomalyApi from '../../api/useAnomalyApi';
import Loader from '../../components/generic/Loader';
import Alert from '../../components/generic/Alert';
import {
  TbCircleNumber1,
  TbCircleNumber2,
  TbCircleNumber3,
} from 'react-icons/tb';
import PrimaryButton from '../../components/generic/PrimaryButton';
import RemoveButton from '../../components/generic/RemoveButton';
import AlertConfirm from '../../components/generic/AlertConfirm';
import AlertDateTime from '../../components/generic/AletDateTime';
import useInterventionApi from '../../api/useInterventionApi';

const Anomaly = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAnomaly, closeAnomaly } = useAnomalyApi();
  const { rescheduleIntervention } = useInterventionApi();
  const [anomaly, setAnomaly] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isCloseAnomalyAlertVisible, setCloseAlertAlertVisible] =
    useState(false);
  const [
    isRescheduleInterventionAlertVisible,
    setRescheduleInterventionAlertVisible,
  ] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAnomaly(id, (response) => {
      if (response?.status === 'success') {
        setAnomaly(response?.content);
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRescheduleIntervention = (date, hour) => {
    setRescheduleInterventionAlertVisible(false);
    setLoading(true);
    rescheduleIntervention(
      anomaly?.intervention?.sub,
      { anomaly_sub: anomaly?.sub, date, hour },
      (response) => {
        if (response?.status === 'success') {
          navigate(-1);
        } else {
          setMessage(response?.message);
        }
        setLoading(false);
      }
    );
  };

  const hanldeCloseAnomaly = () => {
    setLoading(true);
    closeAnomaly(id, (response) => {
      if (response?.status === 'success') {
        navigate(-1);
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
  };

  if (isLoading) {
    return (
      <div className="loader-full-screen">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {message && (
        <Alert message={message} type="error" setMessage={setMessage} />
      )}
      {isCloseAnomalyAlertVisible && (
        <AlertConfirm
          title="Fermer l'anomalie"
          message="Voulez-vous vraiment fermer l'anomalie ?"
          handleClickConfirm={hanldeCloseAnomaly}
          handleClickCancel={() => setCloseAlertAlertVisible(false)}
        />
      )}
      {isRescheduleInterventionAlertVisible && (
        <AlertDateTime
          title="Re-Planifier l'intervention"
          message="Choisir une nouvelle date et heure pour l'intervention"
          initialDate={anomaly?.intervention?.date}
          initialHour={anomaly?.intervention?.hour}
          handleClickConfirm={handleRescheduleIntervention}
          handleClickCancel={() => setRescheduleInterventionAlertVisible(false)}
        />
      )}
      <div>
        <div className="header">
          <h1>Anomalie</h1>
        </div>
      </div>
      <div
        className="page-wrapper"
        style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
      >
        <div className="anomaly-detail-section">
          <div className="anomaly-detail-section-item">
            <span className="anomaly-detail-section-item-label">Client : </span>
            <span className="anomaly-detail-section-item-value">
              {anomaly?.client?.name}
            </span>
          </div>

          <div className="anomaly-detail-section-item">
            <span className="anomaly-detail-section-item-label">
              Intervention :{' '}
            </span>
            <span className="anomaly-detail-section-item-value">
              {anomaly?.intervention?.date} - {anomaly?.intervention?.hour}
            </span>
          </div>

          <div
            className="anomaly-detail-section-item"
            style={{ borderBottom: 'none' }}
          >
            <span className="anomaly-detail-section-item-label">
              Technicien :{' '}
            </span>
            <span className="anomaly-detail-section-item-value">
              {anomaly?.tech?.firstname} {anomaly?.tech?.lastname}
            </span>
          </div>
        </div>

        <div className="anomaly-detail-section">
          <div className="anomaly-detail-section-item">
            <span className="anomaly-detail-section-item-label">Statut : </span>
            <span
              className="anomaly-detail-section-item-value"
              style={{ color: anomaly?.status === 'open' ? 'red' : 'green' }}
            >
              {anomaly?.status === 'open' ? 'Ouvert' : 'Fermé'}
            </span>
            {anomaly?.solution && anomaly?.solution !== '' && (
              <span className="anomaly-detail-section-item-value">
                {anomaly?.solution}
              </span>
            )}
          </div>

          <div className="anomaly-detail-section-item">
            <span className="anomaly-detail-section-item-label">
              Niveau d'urgence :{' '}
            </span>
            <span className="anomaly-detail-section-item-value">
              {anomaly?.urgencyLevel && (
                <>
                  {anomaly?.urgencyLevel === '1' ? (
                    <TbCircleNumber1 size={40} color="yellow" />
                  ) : anomaly?.urgencyLevel === '2' ? (
                    <TbCircleNumber2 size={40} color="orange" />
                  ) : (
                    <TbCircleNumber3 size={40} color="red" />
                  )}
                </>
              )}
            </span>
          </div>

          <div className="anomaly-detail-section-item">
            <span className="anomaly-detail-section-item-label">Type : </span>
            <span className="anomaly-detail-section-item-value">
              {anomaly?.type}
            </span>
          </div>

          <div className="anomaly-detail-section-item">
            <span className="anomaly-detail-section-item-label">
              Description :{' '}
            </span>
            <span className="anomaly-detail-section-item-value">
              {anomaly?.description}
            </span>
          </div>

          <div className="anomaly-detail-section-item">
            <span className="anomaly-detail-section-item-label">Photo : </span>
            <div className="interventionDrawer__section__photo">
              <img src={anomaly?.photo} alt="anomalyPhoto" />
            </div>
          </div>

          <div
            className="anomaly-detail-section-item"
            style={{ borderBottom: 'none' }}
          >
            <span className="anomaly-detail-section-item-label">
              Besoin d'aide :{' '}
            </span>
            <span className="anomaly-detail-section-item-value">
              {anomaly?.needHelp ? "À l'aide" : 'Je gère'}
            </span>
          </div>
        </div>

        <div className="anomaly-detail-section">
          <div
            className="anomaly-detail-section-item"
            style={{ borderBottom: 'none' }}
          >
            <PrimaryButton
              text="Re-Planifier l'intervention"
              type="button"
              handleClick={() => setRescheduleInterventionAlertVisible(true)}
            />
            <RemoveButton
              text="Fermer l'anomalie"
              type="button"
              handleClick={() => setCloseAlertAlertVisible(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Anomaly;
