import React from 'react';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';

const AlertConfirm = ({ title, message, handleClickConfirm, handleClickCancel }) => {
    return (
        <div className='alert-confirm-wrapper'>
            <div className='alert-card'>
                <h3>{title}</h3>
                <p>{message}</p>
                <div>
                    <SecondaryButton text='Annuler' type='button' handleClick={handleClickCancel} />
                    <PrimaryButton text='Confirmer' type='button' handleClick={handleClickConfirm} />
                </div>
            </div>
        </div>
    );
};

export default AlertConfirm;