// LoggedInRoutes.js
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useWindowResize from '../hooks/WindowResize';
import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';
import Staff from '../pages/staff/Staff';
import Clients from '../pages/clients/Clients';
import StaffProfile from '../pages/staff/StaffProfile';
import AddStaff from '../pages/staff/AddStaff';
import ClientProfile from '../pages/clients/ClientProfile';
import AddClient from '../pages/clients/AddClient';
import Planning from '../pages/planning/Planning';
import Anomalies from '../pages/anomalies/Anomalies';
import Anomaly from '../pages/anomalies/Anomaly';
import AddIntervention from '../pages/planning/AddIntervention';

const routes = [
  { path: '/planning', element: <Planning /> },
  { path: '/planning/add', element: <AddIntervention /> },
  { path: '/anomalies', element: <Anomalies /> },
  { path: '/anomalies/:id', element: <Anomaly /> },
  { path: '/staff', element: <Staff /> },
  { path: '/staff/:id', element: <StaffProfile /> },
  { path: '/staff/add', element: <AddStaff /> },
  { path: '/clients', element: <Clients /> },
  { path: '/clients/:id', element: <ClientProfile /> },
  { path: '/clients/add', element: <AddClient /> },
];

const LoggedInRoutes = () => {
  const { isDrawerOpen, handleResize } = useWindowResize();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!routes.some((route) => location.pathname.startsWith(route.path))) {
      navigate('/planning');
    }
  }, [location.pathname, navigate]);

  return (
    <div className={`wrapper ${isDrawerOpen ? 'drawer-open' : 'drawer-close'}`}>
      <AppBar />
      <div
        className={`content ${isDrawerOpen ? 'content-open' : 'content-close'}`}
      >
        <Drawer isDrawerOpen={isDrawerOpen} handleResize={handleResize} />
        <div className="content-body relative">
          <Routes>
            {routes?.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default LoggedInRoutes;
