import { sendRequest } from '../utils/CommonMethods';

// useAuth.js
const useClientApi = () => {
  const getClient = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/clients/${sub}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getClientList = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/clients`;
    sendRequest(url, 'GET', null, callback);
  };

  const getArchivedClientList = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/clients/archive/list`;
    sendRequest(url, 'GET', null, callback);
  };

  const updateClient = async (sub, staff, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/clients/${sub}`;
    sendRequest(url, 'PATCH', staff, callback);
  };

  const disableClient = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/clients/${sub}`;
    sendRequest(url, 'DELETE', null, callback);
  };

  const enableClient = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/clients/${sub}`;
    sendRequest(url, 'PUT', null, callback);
  };

  const createClient = async (staff, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/clients`;
    sendRequest(url, 'POST', staff, callback);
  };

  return {
    getClient,
    getClientList,
    getArchivedClientList,
    updateClient,
    disableClient,
    enableClient,
    createClient,
  };
};

export default useClientApi;
