import React from 'react';
import { MdClose } from 'react-icons/md';

const Chip = ({ text, close, selected, handleClick }) => {
    return (
        <div className={`chip ${!close && handleClick ? 'clickable' : ''} ${selected ? 'selected' : ''}`} onClick={() => { if (!close && handleClick) handleClick(); }}>
            <span>{text}</span>
            {close && <MdClose className='clickable' size={'1.25rem'} onClick={handleClick} />}
        </div>
    );
};

export default Chip;