import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/generic/PrimaryButton';
import Tabs from '../../components/generic/Tabs';
import ClientsTab from './ClientsTab';
import ArchivedClientsTab from './ArchivedClientsTab';

const tabs = [
    'Clients',
    'Archivés'
];

const Clients = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    return (
        <>
            <div>
                <div className='header'>
                    <h1>Clients</h1>
                    <PrimaryButton text='Ajouter un client' type='button' handleClick={() => navigate('/clients/add')} />
                </div>
                <Tabs items={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div className='page-wrapper'>
                {activeTab === 0 && <ClientsTab />}
                {activeTab === 1 && <ArchivedClientsTab />}
            </div>
        </>
    );
};

export default Clients; 