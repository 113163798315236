import { useEffect, useState } from 'react';
import Loader from '../../components/generic/Loader';
import Alert from '../../components/generic/Alert';
import useAnomalyApi from '../../api/useAnomalyApi';
import AnomalyListItem from '../../components/generic/AnomaliListItem';
import { useNavigate } from 'react-router-dom';

const AnomaliesTab = () => {
  const navigate = useNavigate();
  const { getAnomalyList } = useAnomalyApi();
  const [anomalies, setAnomalies] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAnomalyList((response) => {
      if (response?.status === 'success') {
        setAnomalies(response?.content);
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="loader-full-screen">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {message && (
        <Alert message={message} type="error" setMessage={setMessage} />
      )}
      {anomalies?.map((anomaly, key) => (
        <div key={key} onClick={() => navigate(`/anomalies/${anomaly?.sub}`)}>
          <AnomalyListItem anomaly={anomaly} />
        </div>
      ))}
    </>
  );
};

export default AnomaliesTab;
