import React from 'react';

const Input = ({ required, type, placeholder, value = '', id, name, handleChange, disabled }) => {
    return (
        <input required={required ?? false} type={type} name={name} id={id} placeholder={placeholder} value={value} onChange={handleChange} disabled={disabled ?? false} />
    );
};

export default Input;

export const formatPhoneNumber = (phoneNumber) => {
    let value = phoneNumber.trim();

    value = value.replace(/^00336/, '+336');
    value = value.replace(/^00337/, '+337');
    value = value.replace(/^00339/, '+339');
    value = value.replace(/^00334/, '+334');

    value = value.replace(/[^0-9+]/g, '');

    value = value.replace(/\+0+/g, '+');

    if (value.indexOf('+') !== 0) {
        value = value.replace(/\+/g, '');
    }

    if (value.startsWith('06')) {
        value = '+33' + value.substring(1);
    } else if (value.startsWith('07') || value.startsWith('04') || value.startsWith('09')) {
        value = '+33' + value;
    }

    if (value.startsWith('+33') && value.length > 3 && value[3] === '0') {
        value = value.slice(0, 3) + value.slice(4);
    }

    if (value.length > 12) {
        value = value.slice(0, 12);
    }

    return value;
};