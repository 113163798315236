import { sendRequest } from '../utils/CommonMethods';

const useNotificationApi = () => {
  const updatePushToken = async (token, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/notifications/token/update`;
    sendRequest(url, 'POST', { token }, callback);
  };

  const getNotifications = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/notifications`;
    sendRequest(url, 'GET', null, callback);
  };

  const readNotification = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/notifications/${sub}`;
    sendRequest(url, 'PUT', null, callback);
  };

  const readNotifications = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/notifications`;
    sendRequest(url, 'PUT', null, callback);
  };

  return {
    updatePushToken,
    getNotifications,
    readNotification,
    readNotifications,
  };
};

export default useNotificationApi;
