import React from 'react';
import { MdCheck } from 'react-icons/md';
import useColors from '../../hooks/useColors';

const Checkbox = ({ label, checked, handleClick }) => {
    const { white } = useColors();

    return (
        <div className='checkbox-wrapper' onClick={handleClick}>
            <label>{label}</label>
            <div className={`checkbox ${checked ? 'checked' : ''}`}>
                <MdCheck color={white} />
            </div>
        </div>
    );
};

export default Checkbox;