import { sendRequest } from '../utils/CommonMethods';

// useAuth.js
const useAnomalyApi = () => {
  const getAnomaly = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/anomalies/${sub}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getAnomalyList = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/anomalies`;
    sendRequest(url, 'GET', null, callback);
  };

  const getClosedAnomalyList = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/anomalies/closed`;
    sendRequest(url, 'GET', null, callback);
  };

  const updateAnomaly = async (sub, anomaly, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/anomalies/${sub}`;
    sendRequest(url, 'PATCH', anomaly, callback);
  };

  const createAnomaly = async (anomaly, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/anomalies`;
    sendRequest(url, 'POST', anomaly, callback);
  };

  const closeAnomaly = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/anomalies/close/${sub}`;
    sendRequest(url, 'PUT', null, callback);
  };

  return {
    getAnomaly,
    getAnomalyList,
    getClosedAnomalyList,
    updateAnomaly,
    createAnomaly,
    closeAnomaly,
  };
};

export default useAnomalyApi;
