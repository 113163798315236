import { useEffect, useRef, useState } from 'react';
import useInterventionApi from '../../api/useInterventionApi';
import Loader from '../../components/generic/Loader';
import Chip from '../../components/generic/Chip';
import useColors from '../../hooks/useColors';
import Card from '../../components/generic/Card';
import { MdClose, MdCheck } from 'react-icons/md';
import AnomalyListItem from '../../components/generic/AnomaliListItem';
import { useNavigate } from 'react-router-dom';
import SecondaryButton from '../../components/generic/SecondaryButton';
import AlertDateTime from '../../components/generic/AletDateTime';
import Alert from '../../components/generic/Alert';
import RemoveButton from '../../components/generic/RemoveButton';
import AlertConfirm from '../../components/generic/AlertConfirm';

const InterventionDrawer = ({ sub, handleCloseDrawer }) => {
  const navigate = useNavigate();
  const { primaryColor, white, red } = useColors();
  const { getIntervention, rescheduleIntervention, deleteIntervention } =
    useInterventionApi();
  const [intervention, setIntervention] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [
    isRescheduleInterventionAlertVisible,
    setRescheduleInterventionAlertVisible,
  ] = useState(false);
  const [isDeleteInterventionAlertVisible, setDeleteInterventionAlertVisible] =
    useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getIntervention(sub, (response) => {
      if (response?.status === 'success') {
        setIntervention(response.content);
      } else {
        setIntervention(null);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      handleCloseDrawer();
    }
  };

  const handleRescheduleIntervention = (date, hour) => {
    setRescheduleInterventionAlertVisible(false);
    setLoading(true);
    rescheduleIntervention(intervention?.sub, { date, hour }, (response) => {
      if (response?.status === 'success') {
        setRescheduleInterventionAlertVisible(false);
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
  };

  const handleDeleteIntervention = () => {
    setDeleteInterventionAlertVisible(false);
    setLoading(true);
    deleteIntervention(intervention?.sub, (response) => {
      if (response?.status === 'success') {
        setDeleteInterventionAlertVisible(false);
        handleCloseDrawer();
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      {message && (
        <Alert message={message} type="error" setMessage={setMessage} />
      )}
      {isRescheduleInterventionAlertVisible && (
        <AlertDateTime
          title="Re-Planifier l'intervention"
          message="Choisir une nouvelle date et heure pour l'intervention"
          initialDate={intervention?.date}
          initialHour={intervention?.hour}
          handleClickConfirm={handleRescheduleIntervention}
          handleClickCancel={() => setRescheduleInterventionAlertVisible(false)}
        />
      )}
      {isDeleteInterventionAlertVisible && (
        <AlertConfirm
          title="Supprimer l'intervention"
          message="Êtes-vous sûr de vouloir supprimer cette intervention ?"
          handleClickConfirm={handleDeleteIntervention}
          handleClickCancel={() => setDeleteInterventionAlertVisible(false)}
        />
      )}
      <div className="interventionDrawer" onClick={handleOutsideClick}>
        <div className="interventionDrawer__content" ref={contentRef}>
          <div
            className="interventionDrawer__close"
            onClick={handleCloseDrawer}
          >
            <MdClose size={20} color={white} />
          </div>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {intervention?.state !== 'done' && (
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <SecondaryButton
                    text="Re-planifier"
                    type="button"
                    handleClick={() =>
                      setRescheduleInterventionAlertVisible(true)
                    }
                  />
                  <RemoveButton
                    text="Supprimer"
                    type="button"
                    handleClick={() => setDeleteInterventionAlertVisible(true)}
                  />
                </div>
              )}
              {intervention?.anomalies?.length > 0 && (
                <>
                  <h1 className="interventionDrawer__subtitle">Anomalies</h1>
                  <div
                    className="interventionDrawer__section"
                    style={{ border: 'none' }}
                  >
                    {intervention?.anomalies?.map((anomaly, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => navigate(`/anomalies/${anomaly?.sub}`)}
                        >
                          <AnomalyListItem anomaly={anomaly} />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {intervention?.state === undefined ? (
                <h1 className="interventionDrawer__subtitle">
                  Intervention en attente
                </h1>
              ) : (
                <h1 className="interventionDrawer__subtitle">
                  {intervention?.state === 'doing'
                    ? 'Intervention en cours'
                    : 'Intervention terminée'}
                </h1>
              )}
              <div className="interventionDrawer__section">
                {intervention?.state === undefined && (
                  <div
                    className="interventionDrawer__section__item"
                    style={{ borderBottomWidth: 0 }}
                  >
                    <span className="interventionDrawer__section__text">
                      Intervention en attente
                    </span>
                  </div>
                )}
                {(intervention?.state === 'doing' ||
                  intervention?.state === 'done') && (
                  <>
                    <div className="interventionDrawer__section__item">
                      {intervention?.step1 && (
                        <div className="interventionDrawer__section__state__container">
                          <MdCheck size={20} color={white} />
                        </div>
                      )}

                      <span className="interventionDrawer__section__label">
                        Photo d'arrivée
                      </span>
                      {intervention?.step1 ? (
                        <div className="interventionDrawer__section__photo">
                          <img
                            src={intervention?.startingPhoto}
                            alt="startingPhoto"
                          />
                        </div>
                      ) : (
                        <span className="interventionDrawer__section__text">
                          Aucune photo
                        </span>
                      )}
                    </div>

                    <div className="interventionDrawer__section__item">
                      {intervention?.step2 && (
                        <div className="interventionDrawer__section__state__container">
                          <MdCheck size={20} color={white} />
                        </div>
                      )}
                      <span className="interventionDrawer__section__label">
                        Rapport
                      </span>
                      <span className="interventionDrawer__section__text">
                        {intervention?.step2 ? (
                          <>
                            <div>
                              <span
                                className="interventionDrawer__section__text"
                                style={{ fontWeight: 'bold' }}
                              >
                                Chlore :{' '}
                              </span>
                              <span
                                className="interventionDrawer__section__text"
                                style={{
                                  color:
                                    (intervention?.chlore < '2' ||
                                      intervention?.chlore > '5') &&
                                    red,
                                }}
                              >
                                {intervention?.chlore}
                              </span>
                            </div>

                            <div>
                              <span
                                className="interventionDrawer__section__text"
                                style={{ fontWeight: 'bold' }}
                              >
                                PH :{' '}
                              </span>
                              <span
                                className="interventionDrawer__section__text"
                                style={{
                                  color:
                                    (intervention?.ph < '6,9' ||
                                      intervention?.ph > '7,7') &&
                                    red,
                                }}
                              >
                                {intervention?.ph}
                              </span>
                            </div>

                            <div>
                              <span
                                className="interventionDrawer__section__text"
                                style={{ fontWeight: 'bold' }}
                              >
                                Stabilisant :{' '}
                              </span>
                              <span
                                className="interventionDrawer__section__text"
                                style={{
                                  color:
                                    (intervention?.stabilisant < '50' ||
                                      intervention?.stabilisant > '70') &&
                                    red,
                                }}
                              >
                                {intervention?.stabilisant}
                              </span>
                            </div>

                            <div>
                              <span
                                className="interventionDrawer__section__text"
                                style={{ fontWeight: 'bold' }}
                              >
                                Compteur :{' '}
                              </span>
                              <span className="interventionDrawer__section__text">
                                {intervention?.compteur}
                              </span>
                            </div>

                            <div>
                              <span
                                className="interventionDrawer__section__text"
                                style={{ fontWeight: 'bold' }}
                              >
                                Opérations effectuées :{' '}
                              </span>
                              <span className="interventionDrawer__section__text">
                                {intervention?.report}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span className="interventionDrawer__section__text">
                            Aucun rapport
                          </span>
                        )}
                      </span>
                    </div>

                    <div
                      className="interventionDrawer__section__item"
                      style={{ borderBottomWidth: 0 }}
                    >
                      {intervention?.step3 && (
                        <div className="interventionDrawer__section__state__container">
                          <MdCheck size={20} color={white} />
                        </div>
                      )}
                      <span className="interventionDrawer__section__label">
                        Photo de départ
                      </span>
                      {intervention?.step3 ? (
                        <div className="interventionDrawer__section__photo">
                          <img
                            src={intervention?.endingPhoto}
                            alt="endingPhoto"
                          />
                        </div>
                      ) : (
                        <span className="interventionDrawer__section__text">
                          Aucune photo
                        </span>
                      )}

                      <span className="interventionDrawer__section__label">
                        Note
                      </span>
                      <span className="interventionDrawer__section__text">
                        {intervention?.note
                          ? intervention?.note
                          : 'Aucune note'}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <h1 className="interventionDrawer__subtitle">Client</h1>
              <div className="interventionDrawer__section">
                <div className="interventionDrawer__section__item">
                  <span className="interventionDrawer__section__label">
                    {intervention?.client?.name}
                  </span>
                  <span className="interventionDrawer__section__text">
                    {intervention?.client?.client_type}
                  </span>
                </div>

                <div
                  className="interventionDrawer__section__item"
                  style={{ borderBottomWidth: 0 }}
                >
                  <span className="interventionDrawer__section__label">
                    {intervention?.client?.address?.address}{' '}
                    {intervention?.client?.address?.zipcode}{' '}
                    {intervention?.client?.address?.city}
                  </span>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${intervention?.client?.gps?.latitude},${intervention?.client?.gps?.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intervention?.client?.gps?.latitude},{' '}
                    {intervention?.client?.gps?.longitude}
                  </a>
                </div>
              </div>

              <h1 className="interventionDrawer__subtitle">Accès et contact</h1>
              <div className="interventionDrawer__section">
                <div className="interventionDrawer__section__item">
                  <span className="interventionDrawer__section__label">
                    Coordonnées GPS Piscine
                  </span>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${intervention?.client?.gps?.latitude},${intervention?.client?.gps?.longitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intervention?.pool?.gps?.latitude},{' '}
                    {intervention?.pool?.gps?.longitude}
                  </a>
                </div>

                <div className="interventionDrawer__section__item">
                  <span className="interventionDrawer__section__label">
                    Moyen d'accès
                  </span>
                  <div className="interventionDrawer__section__chips">
                    {intervention?.client?.access?.map((item, index) => {
                      return <Chip key={index} text={item} disabled />;
                    })}
                  </div>
                </div>

                <div className="interventionDrawer__section__item">
                  <span className="interventionDrawer__section__label">
                    Note
                  </span>
                  <span className="interventionDrawer__section__text">
                    {intervention?.client?.access_note !== ''
                      ? intervention?.client?.access_note
                      : 'Aucune note'}
                  </span>
                </div>

                <div className="interventionDrawer__section__item">
                  <span className="interventionDrawer__section__label">
                    Contacts
                  </span>
                  <span className="interventionDrawer__section__text">
                    {intervention?.client?.contacts?.length > 0 ? (
                      <>
                        {intervention?.client?.contacts?.map(
                          (contact, index) => {
                            return (
                              <div key={index}>
                                <div>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ fontWeight: 'bold' }}
                                  >
                                    Role :{' '}
                                  </span>
                                  <span className="interventionDrawer__section__text">
                                    {contact?.role}
                                  </span>
                                </div>
                                <div>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ fontWeight: 'bold' }}
                                  >
                                    Nom :{' '}
                                  </span>
                                  <span className="interventionDrawer__section__text">
                                    {contact?.name}
                                  </span>
                                </div>
                                <div>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ fontWeight: 'bold' }}
                                  >
                                    N° téléphone :{' '}
                                  </span>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ color: primaryColor }}
                                  >
                                    {contact?.phone}
                                  </span>
                                </div>
                                <div>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ fontWeight: 'bold' }}
                                  >
                                    Second N° téléphone :{' '}
                                  </span>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ color: primaryColor }}
                                  >
                                    {contact?.second_phone}
                                  </span>
                                </div>
                                <div>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ fontWeight: 'bold' }}
                                  >
                                    Email :{' '}
                                  </span>
                                  <span
                                    className="interventionDrawer__section__text"
                                    style={{ color: primaryColor }}
                                  >
                                    {contact?.email}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <span className="interventionDrawer__section__text">
                        Aucun contact sur place
                      </span>
                    )}
                  </span>
                </div>

                <div
                  className="interventionDrawer__section__item"
                  style={{ borderBottomWidth: 0 }}
                >
                  <span className="interventionDrawer__section__label">
                    Syndic
                  </span>
                  <div>
                    <span
                      className="interventionDrawer__section__text"
                      style={{ fontWeight: 'bold' }}
                    >
                      Nom :{' '}
                    </span>
                    <span className="interventionDrawer__section__text">
                      {intervention?.client?.syndic?.name}
                    </span>
                  </div>

                  <div>
                    <span
                      className="interventionDrawer__section__text"
                      style={{ fontWeight: 'bold' }}
                    >
                      Adresse :{' '}
                    </span>
                    <span className="interventionDrawer__section__text">
                      {intervention?.client?.syndic?.address?.address}{' '}
                      {intervention?.client?.syndic?.address?.zipcode}{' '}
                      {intervention?.client?.syndic?.address?.city}
                    </span>
                  </div>

                  <div>
                    <span
                      className="interventionDrawer__section__text"
                      style={{ fontWeight: 'bold' }}
                    >
                      N° téléphone :{' '}
                    </span>
                    <span
                      className="interventionDrawer__section__text"
                      style={{ color: primaryColor }}
                    >
                      {intervention?.client?.syndic?.phone}
                    </span>
                  </div>

                  <div>
                    <span
                      className="interventionDrawer__section__text"
                      style={{ fontWeight: 'bold' }}
                    >
                      Email :{' '}
                    </span>
                    <span
                      className="interventionDrawer__section__text"
                      style={{ color: primaryColor }}
                    >
                      {intervention?.client?.syndic?.email}
                    </span>
                  </div>
                </div>
              </div>

              <h1 className="interventionDrawer__subtitle">
                Inclusion au contrat
              </h1>
              <div className="interventionDrawer__section">
                <div className="interventionDrawer__section__item">
                  <span className="interventionDrawer__section__label">
                    Inclusion au contrat
                  </span>
                  <div className="interventionDrawer__section__chips">
                    {intervention?.client?.maintenance_info?.contract_inclusion?.map(
                      (item, index) => {
                        return <Chip key={index} text={item} disabled />;
                      }
                    )}
                  </div>
                </div>
                {intervention?.client?.maintenance_info?.easiflo_type !==
                  '' && (
                  <div className="interventionDrawer__section__item">
                    <span className="interventionDrawer__section__label">
                      Type Easiflo
                    </span>
                    <span className="interventionDrawer__section__text">
                      {intervention?.client?.maintenance_info?.easiflo_type}
                    </span>
                  </div>
                )}

                <div
                  className="interventionDrawer__section__item"
                  style={{ borderBottomWidth: 0 }}
                >
                  <span className="interventionDrawer__section__label">
                    Note
                  </span>
                  <span className="interventionDrawer__section__text">
                    {intervention?.client?.maintenance_info
                      ?.contract_inclusion_note !== ''
                      ? intervention?.client?.maintenance_info
                          ?.contract_inclusion_note
                      : 'Aucune note'}
                  </span>
                </div>
              </div>

              <h1 className="interventionDrawer__subtitle">Piscine</h1>
              <div
                className="interventionDrawer__section"
                style={{ borderBottomWidth: 0 }}
              >
                <div className="interventionDrawer__section__item">
                  <Card
                    item={intervention?.pool}
                    //   handleClick={() => {
                    //     setSelectedPool(item?.sub);
                    //     setActiveTab(1);
                    //   }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InterventionDrawer;
