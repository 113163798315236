import React, { useEffect, useState } from 'react';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';
import moment from 'moment';
import { days, hours, months } from '../../utils/StaticData';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MdToday } from 'react-icons/md';
import { HiChevronDown } from 'react-icons/hi';
import { initDropdownItems } from '../../utils/CommonMethods';
import Dropdown from './Dropdown';

moment.locale('fr');

const AlertDateTime = ({
  title,
  message,
  initialDate,
  initialHour,
  handleClickConfirm,
  handleClickCancel,
}) => {
  const [date, setDate] = useState(
    initialDate || moment().format('YYYY-MM-DD')
  );
  const [hour, setHour] = useState(initialHour || '08:00');
  const selectedMoment = moment(date, 'YYYY-MM-DD');
  const startOfMonth = selectedMoment.clone().startOf('month').day();
  const daysInMonth = selectedMoment.daysInMonth();
  const startDayOffset = startOfMonth === 0 ? 6 : startOfMonth - 1;
  const paddedDays = Array.from({ length: startDayOffset }, () => null).concat(
    Array.from({ length: daysInMonth }, (_, i) => i + 1)
  );
  const [isHoursOpen, setHoursOpen] = useState();
  const [dropdownHours, setDropdownHours] = useState([]);

  const renderDayNames = () => (
    <div className="dayNamesContainer">
      {days.map((dayName, index) => (
        <span key={index} className="dayName" style={{ fontSize: 12 }}>
          {dayName}
        </span>
      ))}
    </div>
  );

  const goToNextMonth = () => {
    setDate((prevDay) => moment(prevDay).add(1, 'month').format('YYYY-MM-DD'));
  };

  const goToPrevMonth = () => {
    setDate((prevDay) =>
      moment(prevDay).subtract(1, 'month').format('YYYY-MM-DD')
    );
  };

  const handleTodayPress = () => {
    setDate(moment().format('YYYY-MM-DD'));
  };

  const handleSelectDay = (day) => {
    setDate(moment(day).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    initDropdownItems(hours, (item) => setHour(item), setDropdownHours);
  }, []);

  return (
    <div className="alert-confirm-wrapper">
      <div className="alert-card">
        <h3>{title}</h3>
        <p>{message}</p>
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}
        >
          <div className="calendarContainer">
            <div>
              <div className="calendarHeader">
                <button className="calendarHeaderButton"></button>
                <button
                  onClick={goToPrevMonth}
                  className="calendarHeaderButton"
                  style={{ width: 30, height: 30 }}
                >
                  <FaChevronLeft size={20} color="black" />
                </button>
                <span className="calendarHeaderTitle" style={{ fontSize: 15 }}>
                  {months[selectedMoment.format('M') - 1]}{' '}
                  {selectedMoment.format('YYYY')}
                </span>
                <button
                  onClick={goToNextMonth}
                  className="calendarHeaderButton"
                >
                  <FaChevronRight size={20} color="black" />
                </button>
                <button
                  onClick={handleTodayPress}
                  className="calendarHeaderButton"
                >
                  <MdToday size={30} color="black" />
                </button>
              </div>
              {renderDayNames()}
              <div
                className="monthGrid"
                style={{ height: 300, gridTemplateRows: 50 }}
              >
                {paddedDays.map((day, index) => {
                  const dayKey = `${selectedMoment.format('YYYY-MM')}-${
                    day < 10 ? `0${day}` : day
                  }`;
                  const isSelectedDay = dayKey === date;
                  const isToday = dayKey === moment().format('YYYY-MM-DD');
                  return (
                    <button
                      key={index}
                      className="dayContainer"
                      onClick={() => handleSelectDay(dayKey)}
                      disabled={
                        day === null ||
                        moment(dayKey).isBefore(moment().format('YYYY-MM-DD'))
                      }
                      style={{
                        height: 30,
                        opacity:
                          day === null ||
                          moment(dayKey).isBefore(moment().format('YYYY-MM-DD'))
                            ? 0.5
                            : 1,
                      }}
                    >
                      <div
                        className={`dayContent ${
                          isSelectedDay ? 'selectedDay' : ''
                        }`}
                        style={{
                          border: day === null ? 'none' : '',
                          height: 30,
                        }}
                      >
                        <div
                          className={`day ${isToday ? 'today' : ''}`}
                          style={{ height: 30 }}
                        >
                          <span
                            className={`dayText ${isToday ? 'todayText' : ''}`}
                          >
                            {day}
                          </span>
                        </div>
                        {day && <div style={{ height: 30 }}></div>}
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              textAlign: 'center',
              paddingBottom: 16,
            }}
          >
            <div className="dropdown-container border">
              <div onClick={() => setHoursOpen((prev) => !prev)}>
                <label>{hour && hour !== '' ? hour : 'Heure...'}</label>
                <HiChevronDown size={26} className="clickable" />
              </div>

              {isHoursOpen && (
                <Dropdown
                  isDropdownOpen={isHoursOpen}
                  setDropdownOpen={setHoursOpen}
                  items={dropdownHours}
                />
              )}
            </div>
            <div
              style={{
                border: '1px solid var(--gray)',
                padding: 16,
                borderRadius: 8,
              }}
            >
              <h4>Nouvelle date</h4>
              <span>
                {moment(date).format('DD/MM/YYYY')} à {hour}
              </span>
            </div>
          </div>
        </div>
        <div>
          <SecondaryButton
            text="Annuler"
            type="button"
            handleClick={handleClickCancel}
          />
          <PrimaryButton
            text="Confirmer"
            type="button"
            handleClick={() => handleClickConfirm(date, hour)}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertDateTime;
