// Login.js
import React, { useState } from 'react';
import useAuthApi from '../../api/useAuthApi';
import { useAuthContext } from '../../AuthContext';

import Logo from '../../assets/logos/logo.png';
import PrimaryButton from '../../components/generic/PrimaryButton';
import InputPassword from '../../components/generic/InputPassword';
import Input from '../../components/generic/Input';
import Alert from '../../components/generic/Alert';
import useColors from '../../hooks/useColors';

const Login = () => {
    const { login } = useAuthApi();
    const { loginSession } = useAuthContext();
    const { white } = useColors();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState();
    const [isLoading, setLoading] = useState();

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleLogin = async () => {
        setLoading(true);
        login(email, password, (response) => {
            if (response?.status === 'success') {
                loginSession({
                    auth: response?.auth,
                    sub: response?.sub,
                    expiration: response?.expiration,
                    timestamp: response?.timestamp
                });
                setMessage();
            } else {
                setMessage(response?.message);
            }
            setLoading(false);
        });
    };

    return (
        <>
            {message && <Alert message={message} setMessage={setMessage} type='error' />}
            <div className='wrapper-auth'>
                <div>
                    <img src={Logo} alt='Logo' />
                </div>
                <div>
                    <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                        <div className="input-group">
                            <label htmlFor='email'>Email</label>
                            <Input required type={email === 'root' ? 'text' : 'email'} name='email' id='email' placeholder='Email...' value={email} handleChange={(e) => setEmail(e.target.value)} disabled={isLoading} />
                        </div>
                        <div className="input-group">
                            <label htmlFor="password">Mot de passe</label>
                            <InputPassword required placeholder='Mot de passe...' value={password} name='password' id='id' handleChange={(e) => setPassword(e.target.value)} showPassword={showPassword} togglePasswordVisibility={togglePasswordVisibility} disabled={isLoading} />
                            <div className="forgot">
                                {/* <Link to='/forgot-password'>Forgot Password ?</Link> */}
                            </div>
                        </div>
                        <PrimaryButton type='submit' text='Connexion' isLoading={isLoading} loaderColor={white} />
                    </form>
                </div>
                <div>
                </div>
            </div>
        </>
    );
};

export default Login;
