import { MdCheck } from 'react-icons/md';
import {
  TbCircleNumber1,
  TbCircleNumber2,
  TbCircleNumber3,
} from 'react-icons/tb';

const AnomalyListItem = ({ anomaly }) => {
  return (
    <div className="anomaly-item-container">
      <div className="anomaly-time-content">
        <span className="anomaly-type">{anomaly?.type}</span>
        <span className="anomaly-description">{anomaly?.description}</span>
        {anomaly?.solution && anomaly?.solution !== '' && (
          <span className="anomaly-solution">{anomaly?.solution}</span>
        )}
      </div>
      <div className="anomaly-status-indicator">
        {anomaly?.status === 'closed' ? (
          <MdCheck size={40} color="green" />
        ) : anomaly?.urgencyLevel === '1' ? (
          <TbCircleNumber1 size={40} color="yellow" />
        ) : anomaly?.urgencyLevel === '2' ? (
          <TbCircleNumber2 size={40} color="orange" />
        ) : (
          <TbCircleNumber3 size={40} color="red" />
        )}
      </div>
    </div>
  );
};

export default AnomalyListItem;
