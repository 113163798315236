import { useState } from 'react';
import Tabs from '../../components/generic/Tabs';
import AnomaliesTab from './AnomaliesTab';
import ClosedAnomaliesTab from './ClosedAnomaliesTab';

const tabs = ['Anomalies', 'Fermés'];

const Anomalies = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <div>
        <div className="header">
          <h1>Anomalies</h1>
        </div>
        <Tabs items={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="page-wrapper">
        {activeTab === 0 && <AnomaliesTab />}
        {activeTab === 1 && <ClosedAnomaliesTab />}
      </div>
    </>
  );
};

export default Anomalies;
