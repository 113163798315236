import React, { useState } from 'react';
import Tabs from '../../components/generic/Tabs';
import StaffTab from './StaffTab';
import ArchivedStaffTab from './ArchivedStaffTab';
import PrimaryButton from '../../components/generic/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const tabs = [
    'Staff',
    'Archivés'
];

const Staff = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    return (
        <>
            <div>
                <div className='header'>
                    <h1>Staff</h1>
                    <PrimaryButton text='Ajouter un staff' type='button' handleClick={() => navigate('/staff/add')} />
                </div>
                <Tabs items={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div className='page-wrapper'>
                {activeTab === 0 && <StaffTab />}
                {activeTab === 1 && <ArchivedStaffTab />}
            </div>
        </>
    );
};

export default Staff;