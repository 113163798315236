import React from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

const InputPassword = ({ required, placeholder, value = '', id, name, handleChange, showPassword, togglePasswordVisibility }) => {
    return (
        <div className='password'>
            <input required={required ?? false} type={showPassword ? 'text' : 'password'} name={name} id={id} placeholder={placeholder} value={value} onChange={handleChange} />
            <div className="eye-icon" onClick={togglePasswordVisibility}>
                {showPassword ? <IoMdEyeOff size={24} /> : <IoMdEye size={24} />}
            </div>
        </div>
    );
};

export default InputPassword;