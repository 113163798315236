import React, { useEffect, useState } from 'react';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import useUserApi from '../../api/useUserApi';
import { sectors, staffModel } from '../../utils/StaticData';
import Input, { formatPhoneNumber } from '../../components/generic/Input';
import PhotoInput, { compressImage } from '../../components/generic/PhotoInput';
import Alert from '../../components/generic/Alert';
import RequiredAsterisk from '../../components/generic/RequitredAsterisk';
import InputPassword from '../../components/generic/InputPassword';
import Checkbox from '../../components/generic/Checkbox';
import { HiChevronDown } from 'react-icons/hi';
import Dropdown from '../../components/generic/Dropdown';
import PrimaryButton from '../../components/generic/PrimaryButton';
import SecondaryButton from '../../components/generic/SecondaryButton';
import { initDropdownItems } from '../../utils/CommonMethods';

const roles = ['SEE', 'CS', 'Formateur', 'AE1', 'AE2', 'AE3', 'AE4'];

const AddStaff = () => {
  const navigate = useNavigate();
  const { createUser } = useUserApi();
  const { white } = useColors();
  const [staff, setStaff] = useState(staffModel);
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSectorOpen, setSectorOpen] = useState(false);
  const [dropdownSectors, setDropdownSectors] = useState([]);
  const [message, setMessage] = useState();

  useEffect(() => {
    initDropdownItems(
      sectors,
      (item) => setStaff({ ...staff, sector: item }),
      setDropdownSectors
    );
  }, [staff]);

  const handleTextChange = (e) => {
    setStaff({ ...staff, [e.target.name]: e.target.value });
  };

  const handlePhoneNumberChange = (event) => {
    let value = formatPhoneNumber(event.target.value);

    setStaff({ ...staff, phone: value });
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const compressedFile = await compressImage(file);
      setStaff({ ...staff, photo: compressedFile });
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCheckboxClick = (value) => {
    setStaff({ ...staff, roles: [value?.toLowerCase()] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (staff?.password !== confirmPassword) {
      setMessage('La confirmation du mot de passe ne correspond pas');
      return;
    }

    if (staff?.roles?.length !== 1) {
      setMessage('Séléctionner un role');
      return;
    }

    if (
      !staff?.roles?.includes('see') &&
      (!staff?.sector || staff?.sector === '')
    ) {
      setMessage('Séléctionner un secteur');
      return;
    }

    createUser(staff, (response) => {
      if (response?.status === 'success') {
        setMessage();
        navigate(-1);
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      {message && (
        <Alert message={message} setMessage={setMessage} type="error" />
      )}
      <div>
        <div className="navigation-title">
          <MdChevronLeft
            size={'3rem'}
            className="clickable"
            onClick={() => navigate(-1)}
          />
          <h1>Ajouter un staff</h1>
        </div>
      </div>
      <div className="page-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <h2>Profile</h2>
          <div className="form-section">
            <div>
              <h3>Photo</h3>
              <div>
                {staff?.photo ? (
                  <div className="photo-preview small">
                    <MdClose
                      className="remove-icon"
                      color={white}
                      size="1rem"
                      onClick={() => setStaff({ ...staff, photo: null })}
                    />
                    <img src={staff?.photo} alt="staffPhoto" />
                  </div>
                ) : (
                  <PhotoInput
                    handleDragOver={handleDragOver}
                    handleFileInputChange={handleFileInputChange}
                  />
                )}
              </div>
            </div>

            <div>
              <h3>
                Prénom <RequiredAsterisk />
              </h3>
              <div>
                <Input
                  required={true}
                  type="text"
                  placeholder="Prénom..."
                  value={staff?.firstname}
                  id="firstname"
                  name="firstname"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>
                Nom <RequiredAsterisk />
              </h3>
              <div>
                <Input
                  required={true}
                  type="text"
                  placeholder="Nom..."
                  value={staff?.lastname}
                  id="lastname"
                  name="lastname"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>
                Mot de passe <RequiredAsterisk />
              </h3>
              <div>
                <div>
                  <label>Nouveau mot de passe</label>
                  <InputPassword
                    required
                    placeholder="Mot de passe..."
                    value={staff?.password}
                    id="password"
                    name="password"
                    showPassword={showPassword}
                    togglePasswordVisibility={() =>
                      setShowPassword((prev) => !prev)
                    }
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>Confirmer le mot de passe</label>
                  <InputPassword
                    placeholder="Confirmer le mot de passe..."
                    value={confirmPassword}
                    id="confirmPassword"
                    name="confirmPassword"
                    showPassword={showConfirmPassword}
                    togglePasswordVisibility={() =>
                      setShowConfirmPassword((prev) => !prev)
                    }
                    handleChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div>
              <h3>Téléphone</h3>
              <div>
                <Input
                  type="tel"
                  placeholder="Téléphone..."
                  value={staff?.phone}
                  id="phone"
                  name="phone"
                  handleChange={(e) => handlePhoneNumberChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>
                Email <RequiredAsterisk />
              </h3>
              <div>
                <Input
                  required={true}
                  type="email"
                  placeholder="Email..."
                  value={staff?.email}
                  id="email"
                  name="email"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>
                Role <RequiredAsterisk />
              </h3>
              <div>
                {roles.map((role, index) => {
                  return (
                    <Checkbox
                      key={index}
                      label={role}
                      checked={staff?.roles?.[0]?.toUpperCase() === role}
                      handleClick={() => handleCheckboxClick(role)}
                    />
                  );
                })}
              </div>
            </div>

            {staff?.roles && !staff?.roles?.includes('see') && (
              <div>
                <h3>
                  Secteur <RequiredAsterisk />
                </h3>
                <div>
                  <div className="dropdown-container border">
                    <div onClick={() => setSectorOpen((prev) => !prev)}>
                      <label>
                        {staff?.sector && staff?.sector !== ''
                          ? staff?.sector
                          : 'Sélectionner un secteur...'}
                      </label>
                      <HiChevronDown
                        size={26}
                        className="clickable"
                        onClick={() => setSectorOpen((prev) => !prev)}
                      />
                    </div>

                    {isSectorOpen && (
                      <Dropdown
                        isDropdownOpen={isSectorOpen}
                        setDropdownOpen={setSectorOpen}
                        items={dropdownSectors}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <h2>Véhicule</h2>
          <div className="form-section">
            <div>
              <h3>Immatriculation du véhicule</h3>
              <div>
                <Input
                  type="text"
                  placeholder="Immatriculation..."
                  value={staff?.car_immat}
                  id="car_immat"
                  name="car_immat"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>N° télépéage</h3>
              <div>
                <Input
                  type="text"
                  placeholder="N° télépéage..."
                  value={staff?.tele_ref}
                  id="tele_ref"
                  name="tele_ref"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>
          </div>

          <h2>EPI</h2>
          <div className="form-section">
            <div>
              <h3>Taille t-shirt</h3>
              <div>
                <Input
                  type="text"
                  placeholder="T-shirt..."
                  value={staff?.tshirt}
                  id="tshirt"
                  name="tshirt"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>Taille pantalon</h3>
              <div>
                <Input
                  type="text"
                  placeholder="Pantalon..."
                  value={staff?.pants}
                  id="pants"
                  name="pants"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>Taille chaussures</h3>
              <div>
                <Input
                  type="text"
                  placeholder="Chaussures..."
                  value={staff?.shoes}
                  id="shoes"
                  name="shoes"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="form-button-wrapper">
            <SecondaryButton
              type="button"
              text="Annuler"
              handleClick={() => navigate(-1)}
            />
            <PrimaryButton
              type="submit"
              text="Enregistrer"
              isLoading={isLoading}
              loaderColor={white}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddStaff;
