import { sendRequest } from '../utils/CommonMethods';

// useAuth.js
const usePoolApi = () => {
  const getPool = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/pools/pool/${sub}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getPoolList = async (clientSub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/pools/${clientSub}`;
    sendRequest(url, 'GET', null, callback);
  };

  const updatePool = async (sub, pool, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/pools/pool/${sub}`;
    sendRequest(url, 'PATCH', pool, callback);
  };

  const deletePool = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/pools/pool/${sub}`;
    sendRequest(url, 'DELETE', null, callback);
  };

  const createPool = async (clientSub, pool, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/pools/${clientSub}`;
    sendRequest(url, 'POST', pool, callback);
  };

  return {
    getPool,
    getPoolList,
    updatePool,
    deletePool,
    createPool,
  };
};

export default usePoolApi;
