import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useClientApi from '../../api/useClientApi';
import Alert from '../../components/generic/Alert';
import Table from '../../components/generic/Table';
import { clientsTableCells } from '../../utils/StaticData';
import { formatData } from '../../utils/CommonMethods';

const ArchivedClientsTab = () => {
    const navigate = useNavigate();
    const { getArchivedClientList } = useClientApi();
    const [clientList, setStaffList] = useState([]);
    const [message, setMessage] = useState();

    useEffect(() => {
        getArchivedClientList(response => {
            if (response?.status === 'success') {
                setStaffList(formatData('clients', response?.content, navigate));
            } else {
                setMessage(response?.message);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {message && <Alert message={message} setMessage={setMessage} type='error' />}
            <Table cells={clientsTableCells} data={clientList} />
        </>
    );
};

export default ArchivedClientsTab;