import React from 'react';
import EmptyStatePool from '../../assets/images/EmptyStatePool.png';

const Card = ({ item, handleClick }) => {
    return (
        <div className='card' onClick={handleClick}>
            <div className='card-image-wrapper'>
                {item?.photo && item?.photo !== '' ?
                    <img src={item?.photo} alt='itemPhoto' />
                    :
                    <img src={EmptyStatePool} alt='EmptyStatePool' />
                }
            </div>
            <div>
                <h2>{item?.description}</h2>
                <h3>{item?.gps?.latitude}, {item?.gps?.longitude}</h3>
            </div>
        </div>
    );
};

export default Card;