import React, { createContext, useState, useContext, useEffect } from 'react';
import useNotificationApi from './api/useNotificationApi';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { getNotifications, readNotifications } = useNotificationApi();

  const fetchNotifications = () => {
    getNotifications((response) => {
      if (response?.status === 'success') {
        setNotifications(response?.content);
      }
    });
  };

  const addNotification = (newNotification) => {
    setNotifications((prevNotifications) => [
      newNotification,
      ...prevNotifications,
    ]);
  };

  const readAllNotifications = () => {
    readNotifications((response) => {
      if (response?.status === 'success') {
        fetchNotifications();
      }
    });
  };

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications();
    }, 15000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        fetchNotifications,
        addNotification,
        readAllNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
