import { sendRequest } from '../utils/CommonMethods';

// useAuth.js
const useInterventionApi = () => {
  const addIntervention = async (intervention, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions`;
    sendRequest(url, 'POST', intervention, callback);
  };

  const getIntervention = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/${sub}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getInterventionListByDate = async (date, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/date/${date}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getInterventionListByMonth = async (month, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/month/${month}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getInterventionListByWeek = async (startDate, endDate, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/week/${startDate}/${endDate}`;
    sendRequest(url, 'GET', null, callback);
  };

  const updateIntervention = async (sub, intervention, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/${sub}`;
    sendRequest(url, 'PATCH', intervention, callback);
  };

  const startIntervention = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/intervention/start/${sub}`;
    sendRequest(url, 'PUT', null, callback);
  };

  const rescheduleIntervention = async (sub, content, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/intervention/reschedule/${sub}`;
    sendRequest(url, 'PUT', content, callback);
  };

  const deleteIntervention = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/interventions/${sub}`;
    sendRequest(url, 'DELETE', null, callback);
  };

  return {
    addIntervention,
    getIntervention,
    getInterventionListByDate,
    getInterventionListByMonth,
    getInterventionListByWeek,
    updateIntervention,
    startIntervention,
    rescheduleIntervention,
    deleteIntervention,
  };
};

export default useInterventionApi;
