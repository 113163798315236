import { useEffect, useState } from 'react';
import useAnomalyApi from '../../api/useAnomalyApi';
import Loader from '../../components/generic/Loader';
import Alert from '../../components/generic/Alert';
import AnomalyListItem from '../../components/generic/AnomaliListItem';

const ClosedAnomaliesTab = () => {
  const { getClosedAnomalyList } = useAnomalyApi();
  const [anomalies, setAnomalies] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    setLoading(true);
    getClosedAnomalyList((response) => {
      if (response?.status === 'success') {
        setAnomalies(response?.content);
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="loader-full-screen">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {message && (
        <Alert message={message} type="error" setMessage={setMessage} />
      )}
      <div className="anomalies-wrapper">
        {anomalies?.map((anomaly, index) => {
          return <AnomalyListItem key={index} anomaly={anomaly} />;
        })}
      </div>
    </>
  );
};

export default ClosedAnomaliesTab;
