import { useEffect, useRef, useState } from 'react';
import Alert from '../../components/generic/Alert';
import { useCalendar } from './CalendarContext';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import moment from 'moment';
import { MdToday } from 'react-icons/md';
import { hours } from '../../utils/StaticData';
import useColors from '../../hooks/useColors';
import useInterventionApi from '../../api/useInterventionApi';
import InterventionDrawer from './InterventionDrawer';
import { useDrag } from 'react-use-gesture';
import AlertConfirm from '../../components/generic/AlertConfirm';
import { RiErrorWarningFill } from 'react-icons/ri';

const HOUR_HEIGHT = 60;
const TIME_INDICATOR_HEIGHT = 2;
const HEADER_HEIGHT = 50;
const TIME_COLUMN_WIDTH = 50;

const WeekView = () => {
  const { lightGray, veryLightGray, white, primaryColor, gray } = useColors();
  const [message, setMessage] = useState();
  const { selectedDay, goToToday, setSelectedDay } = useCalendar();
  const { getInterventionListByWeek, rescheduleIntervention } =
    useInterventionApi();
  const [interventions, setInterventions] = useState([]);
  const [openInterventionDrawer, setOpenInterventionDrawer] = useState(false);
  const [selectedInterventionSub, setSelectedInterventionSub] = useState(null);
  const scrollViewRef = useRef(null);
  const [currentTime] = useState(moment());
  const [draggedIntervention, setDraggedIntervention] = useState(null);
  const [newDateTime, setNewDateTime] = useState(null);
  const weekContainerRef = useRef(null);
  const originalDateTimeRef = useRef(null);
  const autoScrollIntervalRef = useRef(null);
  const dragOffsetRef = useRef({ x: 0, y: 0 });
  const [longPressTimer, setLongPressTimer] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [
    isAlertRescheduleInterventionVisible,
    setRescheduleInterventionAlertVisible,
  ] = useState(false);
  const LONG_PRESS_DURATION = 300; // milliseconds

  useEffect(() => {
    scrollToCurrentTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToCurrentTime = () => {
    if (scrollViewRef.current) {
      const currentHour = currentTime.hour();
      const currentMinute = currentTime.minute();
      const scrollPosition =
        currentHour * HOUR_HEIGHT + (currentMinute / 60) * HOUR_HEIGHT;
      scrollViewRef.current.scrollTop = scrollPosition - window.innerHeight / 2;
    }
  };

  useEffect(() => {
    const startOfWeek = moment(selectedDay).startOf('week');
    const endOfWeek = moment(selectedDay).endOf('week');
    getInterventionListByWeek(
      startOfWeek.format('YYYY-MM-DD'),
      endOfWeek.format('YYYY-MM-DD'),
      (response) => {
        if (response?.status === 'success') {
          setInterventions(response?.content);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, openInterventionDrawer]);

  const handleTodayPress = () => {
    goToToday();
    scrollToCurrentTime();
  };

  const goToPrevWeek = () => {
    setSelectedDay((prevDay) =>
      moment(prevDay).subtract(1, 'weeks').format('YYYY-MM-DD')
    );
  };

  const goToNextWeek = () => {
    setSelectedDay((prevDay) =>
      moment(prevDay).add(1, 'weeks').format('YYYY-MM-DD')
    );
  };

  const handleDayPress = (day) => {
    setSelectedDay(day.format('YYYY-MM-DD'));
  };

  const renderWeekHeaders = () => {
    const startOfWeek = moment(selectedDay).startOf('week');
    const endOfWeek = moment(selectedDay).endOf('week');

    return (
      <div
        className="calendarHeader"
        style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <button className="calendarHeaderButton">
          {/* <FaFilter size={30} color="black" /> */}
        </button>
        <button onClick={goToPrevWeek} className="calendarHeaderButton">
          <FaChevronLeft size={40} color="black" cursor="pointer" />
        </button>
        <span className="calendarHeaderTitle">
          {startOfWeek.format('DD MMM YYYY')} -{' '}
          {endOfWeek.format('DD MMM YYYY')}
        </span>
        <button onClick={goToNextWeek} className="calendarHeaderButton">
          <FaChevronRight size={40} color="black" cursor="pointer" />
        </button>
        <button onClick={handleTodayPress} className="calendarHeaderButton">
          <MdToday size={30} color="black" cursor="pointer" />
        </button>
      </div>
    );
  };

  const renderDayHeaders = () => {
    const days = [];
    const startOfWeek = moment(selectedDay).startOf('week');
    const today = moment().startOf('day');

    for (let i = 0; i < 7; i++) {
      const day = moment(startOfWeek).add(i, 'days');
      const isToday = day.isSame(today, 'day');
      const isSelected = day.isSame(moment(selectedDay), 'day');
      days.push(
        <div key={i} className="dayHeader" onClick={() => handleDayPress(day)}>
          <span className="dayHeaderText">{day.format('ddd')}</span>
          <div
            className={`dateHeaderContainer ${isToday && 'todayContainer'} ${
              isSelected && 'selectedContainer'
            }`}
          >
            <span className={`dateHeaderText ${isToday && 'todayText'}`}>
              {day.format('D')}
            </span>
          </div>
        </div>
      );
    }
    return days;
  };

  const renderTimeGrid = () => {
    return hours.map((hour, index) => {
      const isFullHour = hour.endsWith(':00');
      return (
        <div
          key={hour}
          className="timeGridLine"
          style={{
            top: index * (HOUR_HEIGHT / 2),
            borderBottomWidth: isFullHour ? 1 : 0.5,
            borderBottomColor: isFullHour ? lightGray : veryLightGray,
            borderBottom: isFullHour
              ? `1px solid ${lightGray}`
              : `0.5px solid ${veryLightGray}`,
          }}
        />
      );
    });
  };

  const renderTimeSlots = () => {
    const slots = [];
    for (let i = 0; i < 24; i++) {
      slots.push(
        <div key={i} className="timeSlot" style={{ height: HOUR_HEIGHT }}>
          <span className="timeText">{`${i}:00`}</span>
        </div>
      );
    }
    return slots;
  };

  const calculateNewDateTime = (dx, dy) => {
    if (!originalDateTimeRef.current || !weekContainerRef.current) return null;

    const weekContainer = weekContainerRef.current;
    const rect = weekContainer.getBoundingClientRect();
    const dayWidth = rect.width / 7;
    const halfHourHeight = HOUR_HEIGHT / 2;

    const originalDate = moment(originalDateTimeRef.current.date);
    const originalTime = moment(originalDateTimeRef.current.time, 'HH:mm');

    const daysDiff = Math.round(dx / dayWidth);
    const halfHoursDiff = Math.round(dy / halfHourHeight);

    const newDate = originalDate.clone().add(daysDiff, 'days');
    const newTime = originalTime.clone().add(halfHoursDiff * 30, 'minutes');

    return {
      date: newDate.format('YYYY-MM-DD'),
      time: newTime.format('HH:mm'),
    };
  };

  const handleDragStart = (intervention) => {
    setDraggedIntervention(intervention);
    setIsDragging(true);
    originalDateTimeRef.current = {
      date: intervention.date,
      time: intervention.hour,
    };
  };

  const startAutoScroll = (direction) => {
    if (autoScrollIntervalRef.current) return;

    autoScrollIntervalRef.current = setInterval(() => {
      const scrollView = scrollViewRef.current;
      if (!scrollView) return;

      const scrollStep = 50; // Reduced scroll speed for smoother scrolling
      if (direction === 'up' && scrollView.scrollTop > 0) {
        scrollView.scrollTop -= scrollStep;
        dragOffsetRef.current.y -= 10;
      } else if (
        direction === 'down' &&
        scrollView.scrollTop < scrollView.scrollHeight - scrollView.clientHeight
      ) {
        scrollView.scrollTop += scrollStep;
        dragOffsetRef.current.y += 10;
      }

      if (draggedIntervention) {
        const newDT = calculateNewDateTime(
          dragOffsetRef.current.x,
          dragOffsetRef.current.y
        );
        if (newDT) {
          setNewDateTime(newDT);
        }
      }
    }, 50); // Run at ~60fps for smoother scrolling
  };

  const stopAutoScroll = () => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
      autoScrollIntervalRef.current = null;
    }
  };

  const handleAutoScroll = (clientY) => {
    const scrollView = scrollViewRef.current;
    if (!scrollView) return;

    const { top, bottom } = scrollView.getBoundingClientRect();
    const scrollThreshold = 10; // Adjust this value to change the auto-scroll trigger area

    if (clientY < top + scrollThreshold) {
      startAutoScroll('up');
    } else if (clientY > bottom - scrollThreshold) {
      startAutoScroll('down');
    } else {
      stopAutoScroll();
    }
  };

  const bind = useDrag(
    ({ movement: [dx, dy], xy: [, clientY], first, last }) => {
      if (!draggedIntervention) return;

      if (first) {
        dragOffsetRef.current = { x: 0, y: 0 };
      }

      dragOffsetRef.current.x = dx;
      dragOffsetRef.current.y = dy;

      handleAutoScroll(clientY);

      const newDT = calculateNewDateTime(dx, dy);
      if (newDT) {
        setNewDateTime(newDT);

        if (last) {
          setRescheduleInterventionAlertVisible(true);
          stopAutoScroll();
        }
      }
    }
  );

  const handleRescheduleIntervention = (sub, newDateTime) => {
    if (draggedIntervention && newDateTime) {
      rescheduleIntervention(
        draggedIntervention.sub,
        { date: newDateTime.date, hour: newDateTime.time },
        (response) => {
          if (response?.status === 'success') {
            updateInterventionPosition(draggedIntervention.sub, newDateTime);
          } else {
            resetInterventionPosition();
          }
        }
      );
    }
    setDraggedIntervention(null);
    setNewDateTime(null);
    originalDateTimeRef.current = null;
    setRescheduleInterventionAlertVisible(false);
  };

  const resetInterventionPosition = () => {
    // Reset dragging state
    setIsDragging(false);
    setDraggedIntervention(null);
    setNewDateTime(null);
  };

  const updateInterventionPosition = (sub, newDateTime) => {
    setInterventions((prevInterventions) =>
      prevInterventions.map((intervention) =>
        intervention.sub === sub
          ? { ...intervention, date: newDateTime.date, hour: newDateTime.time }
          : intervention
      )
    );
  };

  const renderInterventions = (day) => {
    const dayInterventions = interventions.filter(
      (intervention) =>
        moment(intervention.date).format('YYYY-MM-DD') ===
        day.format('YYYY-MM-DD')
    );

    // Sort interventions by start time
    dayInterventions.sort((a, b) =>
      moment(a.hour, 'HH:mm').diff(moment(b.hour, 'HH:mm'))
    );

    const timeSlots = {};

    return dayInterventions.map((intervention, index) => {
      const startTime = moment(
        intervention.date + ' ' + intervention.hour,
        'YYYY-MM-DD HH:mm'
      );
      const top = (startTime.hours() + startTime.minutes() / 60) * HOUR_HEIGHT;
      const duration = intervention.duration || 60;
      const height = (duration * HOUR_HEIGHT) / 60;

      const timeKey = startTime.format('HH:mm');
      if (!timeSlots[timeKey]) {
        timeSlots[timeKey] = [];
      }
      timeSlots[timeKey].push(intervention);

      const overlappingCount = timeSlots[timeKey].length;
      const width = `${100 / overlappingCount}%`;
      const left = `${
        (timeSlots[timeKey].indexOf(intervention) * 100) / overlappingCount
      }%`;

      return (
        <div
          key={index}
          className="intervention"
          style={{
            top,
            height,
            width,
            left,
            backgroundColor: !intervention?.state
              ? white
              : intervention?.state === 'doing'
              ? gray
              : primaryColor,
            border: `1px solid ${gray}`,
            cursor:
              draggedIntervention?.sub === intervention.sub
                ? 'move'
                : 'pointer',
            position: 'absolute',
            transform:
              draggedIntervention?.sub === intervention.sub
                ? `translate(${
                    newDateTime
                      ? calculateTranslate(newDateTime, intervention)
                      : '0px, 0px'
                  })`
                : 'none',
          }}
          {...bind()}
          onMouseDown={(e) => handleInterventionMouseDown(e, intervention)}
          onMouseUp={() => handleInterventionMouseUp(intervention)}
          onMouseLeave={() => {
            if (longPressTimer) {
              clearTimeout(longPressTimer);
              setLongPressTimer(null);
            }
          }}
        >
          {(intervention?.stabilisant < '50' ||
            intervention?.stabilisant > '70' ||
            intervention?.ph < '6,9' ||
            intervention?.ph > '7,7' ||
            intervention?.cholre < '2' ||
            intervention?.cholre > '5') && (
            <div className="warning-badge" style={{ bottom: 0, left: 0 }}>
              <RiErrorWarningFill size={20} color="red" />
            </div>
          )}
          <span
            className="interventionText"
            style={{
              color: !intervention?.state
                ? 'black'
                : intervention?.state === 'doing' ||
                  (intervention?.state === 'done' && white),
            }}
          >
            {intervention?.pool?.description} - {intervention?.client?.name}
          </span>
        </div>
      );
    });
  };

  const calculateTranslate = (newDateTime, intervention) => {
    const daysDiff = moment(newDateTime.date).diff(
      moment(intervention.date),
      'days'
    );
    const minutesDiff = moment(newDateTime.time, 'HH:mm').diff(
      moment(intervention.hour, 'HH:mm'),
      'minutes'
    );

    const xTranslate =
      daysDiff * (weekContainerRef.current.getBoundingClientRect().width / 7);
    const yTranslate = (minutesDiff / 60) * HOUR_HEIGHT;

    return `${xTranslate}px, ${yTranslate}px`;
  };

  const renderWeekDays = () => {
    const days = [];
    const startOfWeek = moment(selectedDay).startOf('week');

    for (let i = 0; i < 7; i++) {
      const day = moment(startOfWeek).add(i, 'days');
      days.push(
        <div
          key={i}
          className="dayColumn"
          style={{
            width: `calc((100% / 7)`,
          }}
        >
          <div
            className="interventionsContainer"
            style={{ height: 24 * HOUR_HEIGHT }}
          >
            {renderInterventions(day)}
          </div>
        </div>
      );
    }
    return days;
  };

  const renderCurrentTimeIndicator = () => {
    const now = moment();
    const top = (now.hours() + now.minutes() / 60) * HOUR_HEIGHT;

    return (
      <div className="currentTimeIndicator" style={{ top }}>
        <div
          className="currentTimeIndicatorCircle"
          style={{
            marginLeft: TIME_COLUMN_WIDTH - 5,
          }}
        />
        <div
          className="currentTimeIndicatorLine"
          style={{ height: TIME_INDICATOR_HEIGHT }}
        />
      </div>
    );
  };

  const handleInterventionClick = (sub) => {
    setSelectedInterventionSub(sub);
    setOpenInterventionDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenInterventionDrawer(false);
    setSelectedInterventionSub(null);
  };

  const handleInterventionMouseDown = (e, intervention) => {
    e.preventDefault();
    const timer = setTimeout(() => {
      handleDragStart(intervention);
    }, LONG_PRESS_DURATION);
    setLongPressTimer(timer);
  };

  const handleInterventionMouseUp = (intervention) => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
    if (!isDragging) {
      handleInterventionClick(intervention?.sub);
    }
  };

  return (
    <>
      {message && (
        <Alert message={message} setMessage={setMessage} type="error" />
      )}
      {openInterventionDrawer && (
        <InterventionDrawer
          sub={selectedInterventionSub}
          handleCloseDrawer={handleCloseDrawer}
        />
      )}
      {isAlertRescheduleInterventionVisible && (
        <AlertConfirm
          title="Confirmer le déplacement"
          message={`Voulez-vous déplacer l'intervention au ${newDateTime?.date} à ${newDateTime?.time} ?`}
          handleClickConfirm={() =>
            handleRescheduleIntervention(draggedIntervention.sub, newDateTime)
          }
          handleClickCancel={() => {
            setRescheduleInterventionAlertVisible(false);
            setIsDragging(false);
            setDraggedIntervention(null);
          }}
        />
      )}
      <div className="weekViewContainer">
        <div className="weekHeader">
          {renderWeekHeaders()}
          <div className="weekHeaderRow" style={{ height: HEADER_HEIGHT }}>
            <div
              className="weekTimeColumnHeader"
              style={{ width: TIME_COLUMN_WIDTH }}
            />
            {renderDayHeaders()}
          </div>
        </div>
        <div className="weekScrollContainer" ref={scrollViewRef}>
          <div className="timeColumn" style={{ height: 24 * HOUR_HEIGHT }}>
            {renderTimeSlots()}
          </div>
          <div
            className="weekContainer"
            style={{ height: 24 * HOUR_HEIGHT }}
            ref={weekContainerRef}
          >
            <div className="weekTimeColumn" />
            <div className="weekDayScrollView">
              {renderTimeGrid()}
              {renderWeekDays()}
              {renderCurrentTimeIndicator()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeekView;
