import React, { useEffect, useRef } from 'react';

const Dropdown = ({
  isDropdownOpen,
  setDropdownOpen,
  items,
  multiselect = false,
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen, setDropdownOpen, dropdownRef]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      {items?.length > 0 ? (
        <>
          {items?.map((item, index) => {
            return (
              <div
                className="item"
                key={index}
                onClick={() => {
                  item.action();
                  if (!multiselect) setDropdownOpen(false);
                }}
              >
                <h4>{item?.label}</h4>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div
            className="item"
            onClick={() => {
              setDropdownOpen(false);
            }}
          >
            <span>Aucune donnée disponible..</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
