import React, { useEffect, useState } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useUserApi from '../../api/useUserApi';
import useColors from '../../hooks/useColors';
import {
  access,
  clientModel,
  clientTypes,
  days,
  duration,
  easifloType,
  hours,
  inclusion,
  months,
  sectors,
} from '../../utils/StaticData';
import moment from 'moment';
import useClientApi from '../../api/useClientApi';
import Alert from '../../components/generic/Alert';
import RequiredAsterisk from '../../components/generic/RequitredAsterisk';
import { HiChevronDown } from 'react-icons/hi';
import Dropdown from '../../components/generic/Dropdown';
import Input from '../../components/generic/Input';
import Chip from '../../components/generic/Chip';
import RemoveButton from '../../components/generic/RemoveButton';
import PrimaryButton from '../../components/generic/PrimaryButton';
import Checkbox from '../../components/generic/Checkbox';
import SecondaryButton from '../../components/generic/SecondaryButton';
import { clientTextChange, initDropdownItems } from '../../utils/CommonMethods';

const AddClient = () => {
  const navigate = useNavigate();
  const { getUserListByRole } = useUserApi();
  const { createClient } = useClientApi();
  const { white } = useColors();
  const [client, setClient] = useState(clientModel);
  const [techs, setTechs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [isSectorOpen, setSectorOpen] = useState();
  const [dropdownSectors, setDropdownSectors] = useState([]);
  const [isTechOpen, setTechOpen] = useState();
  const [dropdownTech, setDropdownTech] = useState([]);
  const [isClientTypeOpen, setClientTypeOpen] = useState();
  const [dropdownClientType, setDropdownClientType] = useState([]);
  const [isAccessOpen, setAccessOpen] = useState();
  const [dropdownAccess, setDropdownAccess] = useState([]);
  const [isHoursOpen, setHoursOpen] = useState();
  const [dropdownHours, setDropdownHours] = useState([]);
  const [isDurationOpen, setDurationOpen] = useState();
  const [dropdownDuration, setDropdownDuration] = useState([]);
  const [isContractInclusionOpen, setContractInclusionOpen] = useState();
  const [dropdownContractInclusion, setDropdownContractInclusion] = useState(
    []
  );
  const [isEasifloOpen, setEasifloOpen] = useState();
  const [dropdownEasiflo, setDropdownEasiflo] = useState([]);

  const handleTextChange = (e) => {
    clientTextChange(e, client, setClient);
  };

  useEffect(() => {
    initDropdownItems(
      sectors,
      (item) => setClient({ ...client, sector: item }),
      setDropdownSectors
    );
    initDropdownItems(
      clientTypes,
      (item) => setClient({ ...client, client_type: item }),
      setDropdownClientType
    );
    initDropdownItems(
      hours,
      (item) =>
        setClient({
          ...client,
          maintenance_info: { ...client?.maintenance_info, hour: item },
        }),
      setDropdownHours
    );
    initDropdownItems(
      easifloType,
      (item) =>
        setClient({
          ...client,
          maintenance_info: { ...client?.maintenance_info, easiflo_type: item },
        }),
      setDropdownEasiflo
    );
    initDropdownItems(
      access,
      (item) => {
        if (client?.access?.includes(item)) {
          setClient({
            ...client,
            access: client?.access?.filter((el) => el !== item),
          });
        } else {
          setClient({ ...client, access: [...client?.access, item] });
        }
      },
      setDropdownAccess
    );
    initDropdownItems(
      inclusion,
      (item) => {
        if (client?.maintenance_info?.contract_inclusion?.includes(item)) {
          setClient({
            ...client,
            maintenance_info: {
              ...client?.maintenance_info,
              contract_inclusion:
                client?.maintenance_info?.contract_inclusion?.filter(
                  (el) => el !== item
                ),
            },
          });
        } else {
          setClient({
            ...client,
            maintenance_info: {
              ...client?.maintenance_info,
              contract_inclusion: [
                ...client?.maintenance_info?.contract_inclusion,
                item,
              ],
            },
          });
        }
      },
      setDropdownContractInclusion
    );
    let res = [];
    // eslint-disable-next-line array-callback-return
    duration?.map((item) => {
      res.push({
        label: `${moment.duration(item, 'minutes').hours()}h ${moment
          .duration(item, 'minutes')
          .minutes()}m`,
        action: () =>
          setClient({
            ...client,
            maintenance_info: { ...client?.maintenance_info, duration: item },
          }),
      });
    });
    setDropdownDuration(res);
  }, [client]);

  useEffect(() => {
    let res = [];
    // eslint-disable-next-line array-callback-return
    inclusion?.map((item) => {
      res.push({
        label: item,
        action: () => {
          if (client?.maintenance_info?.contract_inclusion?.includes(item)) {
            setClient({
              ...client,
              maintenance_info: {
                ...client?.maintenance_info,
                contract_inclusion:
                  client?.maintenance_info?.contract_inclusion?.filter(
                    (el) => el !== item
                  ),
              },
            });
          } else {
            setClient({
              ...client,
              maintenance_info: {
                ...client?.maintenance_info,
                contract_inclusion: [
                  ...client?.maintenance_info?.contract_inclusion,
                  item,
                ],
              },
            });
          }
        },
      });
    });
    setDropdownContractInclusion(res);
  }, [client]);

  useEffect(() => {
    getUserListByRole('ae1-ae2-ae3-ae4', (response) => {
      if (response?.status === 'success') {
        let res = [];
        // eslint-disable-next-line array-callback-return
        response?.content?.map((item) => {
          res.push({
            label: `${item?.firstname ?? ''} ${item?.lastname ?? ''} (${
              item?.roles?.[0]?.toUpperCase() ?? ''
            })`,
            action: () => setClient({ ...client, user_sub: item?.sub }),
          });
        });
        setTechs(response?.content);
        setDropdownTech(res);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!client?.sector || client?.sector === '') {
      setMessage('Séléctionner un secteur');
      return;
    }

    if (!client?.client_type || client?.client_type === '') {
      setMessage('Séléctionner le type du client');
      return;
    }

    setLoading(true);
    createClient(client, (response) => {
      if (response?.status === 'success') {
        setMessage();
        navigate(-1);
      } else {
        setMessage(response?.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      {message && (
        <Alert message={message} setMessage={setMessage} type="error" />
      )}
      <div>
        <div className="navigation-title">
          <MdChevronLeft
            size={'3rem'}
            className="clickable"
            onClick={() => navigate(-1)}
          />
          <h1>Ajouter un client</h1>
        </div>
      </div>
      <div className="page-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <h2>Secteur et technicien</h2>
          <div className="form-section">
            <div>
              <h3>
                Secteur <RequiredAsterisk />
              </h3>
              <div>
                <div className="dropdown-container border">
                  <div onClick={() => setSectorOpen((prev) => !prev)}>
                    <label>
                      {client?.sector && client?.sector !== ''
                        ? client?.sector
                        : 'Secteur...'}
                    </label>
                    <HiChevronDown size={26} className="clickable" />
                  </div>

                  {isSectorOpen && (
                    <Dropdown
                      isDropdownOpen={isSectorOpen}
                      setDropdownOpen={setSectorOpen}
                      items={dropdownSectors}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h3>Technicien</h3>
              <div>
                <div className="dropdown-container border">
                  <div onClick={() => setTechOpen((prev) => !prev)}>
                    <label>
                      {client?.user_sub && client?.user_sub !== ''
                        ? `${
                            techs?.filter(
                              (el) => el?.sub === client?.user_sub
                            )?.[0]?.firstname
                          } ${
                            techs?.filter(
                              (el) => el?.sub === client?.user_sub
                            )?.[0]?.lastname
                          } (${techs
                            ?.filter((el) => el?.sub === client?.user_sub)?.[0]
                            ?.roles?.[0]?.toUpperCase()})`
                        : 'Technicien...'}
                    </label>
                    <HiChevronDown size={26} className="clickable" />
                  </div>

                  {isTechOpen && (
                    <Dropdown
                      isDropdownOpen={isTechOpen}
                      setDropdownOpen={setTechOpen}
                      items={dropdownTech}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <h2>Client</h2>
          <div className="form-section">
            <div>
              <h3>
                Type du client <RequiredAsterisk />
              </h3>
              <div>
                <div className="dropdown-container border">
                  <div onClick={() => setClientTypeOpen((prev) => !prev)}>
                    <label>
                      {client?.client_type && client?.client_type !== ''
                        ? client?.client_type
                        : 'Type du client...'}
                    </label>
                    <HiChevronDown size={26} className="clickable" />
                  </div>

                  {isClientTypeOpen && (
                    <Dropdown
                      isDropdownOpen={isClientTypeOpen}
                      setDropdownOpen={setClientTypeOpen}
                      items={dropdownClientType}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h3>
                Nom <RequiredAsterisk />
              </h3>
              <div>
                <Input
                  required={true}
                  type="text"
                  placeholder="Nom..."
                  value={client?.name}
                  id="name"
                  name="name"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>Adresse</h3>
              <div>
                <div>
                  <label>N° et rue</label>
                  <Input
                    type="text"
                    placeholder="N° et rue..."
                    value={client?.address?.address}
                    id="address"
                    name="address"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>Code postale</label>
                  <Input
                    type="text"
                    placeholder="Code postale..."
                    value={client?.address?.zipcode}
                    id="address"
                    name="zipcode"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>Ville</label>
                  <Input
                    type="text"
                    placeholder="Ville..."
                    value={client?.address?.city}
                    id="address"
                    name="city"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
              </div>
            </div>

            <div>
              <h3>Date de début du contrat</h3>
              <div>
                <Input
                  type="text"
                  placeholder={`ex: ${moment().format('DD/MM/YYYY')}...`}
                  value={client?.contract_date}
                  id="contract_date"
                  name="contract_date"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>
          </div>

          <h2>Accès et contrat</h2>
          <div className="form-section">
            <div>
              <h3>Coordonnées GPS</h3>
              <div>
                <div>
                  <label>Latitude</label>
                  <Input
                    type="text"
                    placeholder="Latitude..."
                    value={client?.gps?.latitude}
                    id="gps"
                    name="latitude"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>Longitude</label>
                  <Input
                    type="text"
                    placeholder="Longitude..."
                    value={client?.gps?.longitude}
                    id="gps"
                    name="longitude"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
              </div>
            </div>

            <div>
              <h3>Moyen d'accès</h3>
              <div className="full-space">
                <div className="dropdown-container border">
                  <div onClick={() => setAccessOpen((prev) => !prev)}>
                    <label>Moyen d'access...</label>
                    <HiChevronDown size={26} className="clickable" />
                  </div>

                  {isAccessOpen && (
                    <Dropdown
                      isDropdownOpen={isAccessOpen}
                      setDropdownOpen={setAccessOpen}
                      items={dropdownAccess}
                      multiselect={true}
                    />
                  )}
                </div>
                <div>
                  <div className="chips-wrapper">
                    {client?.access?.map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          text={item}
                          close
                          handleClick={() =>
                            setClient({
                              ...client,
                              access: client?.access?.filter(
                                (el) => el !== item
                              ),
                            })
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Note</h3>
              <div>
                <Input
                  type="text"
                  placeholder="Note..."
                  value={client?.access_note}
                  id="access_note"
                  name="access_note"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>

            <div>
              <h3>Contacts</h3>
              <div>
                {client?.contacts?.length > 0 ? (
                  <>
                    {client?.contacts?.map((item, index) => {
                      return (
                        <div key={index}>
                          <h3>Contact {index + 1}</h3>
                          <div>
                            <label>Role</label>
                            <Input
                              type="text"
                              placeholder="Role..."
                              value={item?.role}
                              id="contacts"
                              name={`role-${index}`}
                              handleChange={(e) => handleTextChange(e)}
                            />
                          </div>
                          <div>
                            <label>Nom</label>
                            <Input
                              type="text"
                              placeholder="Nom..."
                              value={item?.name}
                              id="contacts"
                              name={`name-${index}`}
                              handleChange={(e) => handleTextChange(e)}
                            />
                          </div>
                          <div>
                            <label>N° téléphone</label>
                            <Input
                              type="text"
                              placeholder="N° téléphone..."
                              value={item?.phone}
                              id="contacts"
                              name={`phone-${index}`}
                              handleChange={(e) => handleTextChange(e)}
                            />
                          </div>
                          <div>
                            <label>Second N° téléphone</label>
                            <Input
                              type="text"
                              placeholder="Second N° téléphone..."
                              value={item?.second_phone}
                              id="contacts"
                              name={`second_phone-${index}`}
                              handleChange={(e) => handleTextChange(e)}
                            />
                          </div>
                          <div>
                            <label>Email</label>
                            <Input
                              type="email"
                              placeholder="Email..."
                              value={item?.email}
                              id="contacts"
                              name={`email-${index}`}
                              handleChange={(e) => handleTextChange(e)}
                            />
                          </div>
                          <RemoveButton
                            text="Supprimer le contact ?"
                            type="button"
                            handleClick={() =>
                              setClient({
                                ...client,
                                contacts: client?.contacts?.filter(
                                  (_, i) => i !== index
                                ),
                              })
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <label>Aucun contact sur place...</label>
                )}
              </div>
              <div></div>
              <div>
                <PrimaryButton
                  text="Ajouter un contact sur place ?"
                  type="button"
                  handleClick={() =>
                    setClient({
                      ...client,
                      contacts: [
                        ...client?.contacts,
                        {
                          role: '',
                          name: '',
                          phone: '',
                          second_phone: '',
                          email: '',
                        },
                      ],
                    })
                  }
                />
              </div>
            </div>

            <div>
              <h3>Syndic</h3>
              <div>
                <div>
                  <label>Nom</label>
                  <Input
                    type="text"
                    placeholder="Nom..."
                    value={client?.syndic?.name}
                    id="syndic"
                    name="name"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>N° téléphone</label>
                  <Input
                    type="text"
                    placeholder="N° téléphone..."
                    value={client?.syndic?.phone}
                    id="syndic"
                    name="phone"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>Email</label>
                  <Input
                    type="email"
                    placeholder="Email..."
                    value={client?.syndic?.email}
                    id="syndic"
                    name="email"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>N° et rue</label>
                  <Input
                    type="text"
                    placeholder="N° et rue..."
                    value={client?.syndic?.address?.address}
                    id="syndic.address"
                    name="address"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>Code postale</label>
                  <Input
                    type="text"
                    placeholder="Code postale..."
                    value={client?.syndic?.address?.zipcode}
                    id="syndic.address"
                    name="zipcode"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
                <div>
                  <label>Ville</label>
                  <Input
                    type="text"
                    placeholder="Ville..."
                    value={client?.syndic?.address?.city}
                    id="syndic.address"
                    name="city"
                    handleChange={(e) => handleTextChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <h2>Fréquence d'entretien</h2>
          <div className="form-section">
            <div>
              <h3>Préférences des passages</h3>
              <div>
                <div>
                  <div className="chips-wrapper">
                    {days?.map((item, index) => {
                      return (
                        <Chip
                          key={index}
                          text={item}
                          selected={client?.maintenance_info?.days?.includes(
                            item
                          )}
                          handleClick={() => {
                            if (
                              !client?.maintenance_info?.days?.includes(item)
                            ) {
                              setClient({
                                ...client,
                                maintenance_info: {
                                  ...client?.maintenance_info,
                                  days: [
                                    ...client?.maintenance_info?.days,
                                    item,
                                  ],
                                },
                              });
                            } else {
                              setClient({
                                ...client,
                                maintenance_info: {
                                  ...client?.maintenance_info,
                                  days: client?.maintenance_info?.days?.filter(
                                    (el) => el !== item
                                  ),
                                },
                              });
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Durée des passages</h3>
              <div>
                <div className="dropdown-container border">
                  <div onClick={() => setHoursOpen((prev) => !prev)}>
                    <label>
                      {client?.maintenance_info?.hour &&
                      client?.maintenance_info?.hour !== ''
                        ? client?.maintenance_info?.hour
                        : 'Heure...'}
                    </label>
                    <HiChevronDown size={26} className="clickable" />
                  </div>

                  {isHoursOpen && (
                    <Dropdown
                      isDropdownOpen={isHoursOpen}
                      setDropdownOpen={setHoursOpen}
                      items={dropdownHours}
                    />
                  )}
                </div>
                <div className="dropdown-container border">
                  <div onClick={() => setDurationOpen((prev) => !prev)}>
                    <label>
                      {client?.maintenance_info?.duration &&
                      client?.maintenance_info?.duration !== ''
                        ? `${moment
                            .duration(
                              client?.maintenance_info?.duration,
                              'minutes'
                            )
                            .hours()}h ${moment
                            .duration(
                              client?.maintenance_info?.duration,
                              'minutes'
                            )
                            .minutes()}m`
                        : 'Durée...'}
                    </label>
                    <HiChevronDown size={26} className="clickable" />
                  </div>

                  {isDurationOpen && (
                    <Dropdown
                      isDropdownOpen={isDurationOpen}
                      setDropdownOpen={setDurationOpen}
                      items={dropdownDuration}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h3>Fréquence d'entretien</h3>
              <div className="full-space grid">
                <div>
                  {client?.maintenance_info?.frequency.map((item, index) => {
                    return (
                      <div key={index}>
                        <Chip text={months[index]} selected />
                        <Input
                          type="number"
                          value={item?.reccurence}
                          id="maintenance_info.frequency"
                          name={`reccurence-${index}`}
                          handleChange={(e) => handleTextChange(e)}
                        />
                        <div>
                          <Checkbox
                            label="Jour"
                            checked={item?.period === 'day'}
                            handleClick={() => {
                              let frequency =
                                client?.maintenance_info?.frequency;
                              frequency[index]['period'] = 'day';
                              setClient({
                                ...client,
                                maintenance_info: {
                                  ...client?.maintenance_info,
                                  frequency: frequency,
                                },
                              });
                            }}
                          />
                          <Checkbox
                            label="Semaine"
                            checked={item?.period === 'week'}
                            handleClick={() => {
                              let frequency =
                                client?.maintenance_info?.frequency;
                              frequency[index]['period'] = 'week';
                              setClient({
                                ...client,
                                maintenance_info: {
                                  ...client?.maintenance_info,
                                  frequency: frequency,
                                },
                              });
                            }}
                          />
                          <Checkbox
                            label="Mois"
                            checked={item?.period === 'month'}
                            handleClick={() => {
                              let frequency =
                                client?.maintenance_info?.frequency;
                              frequency[index]['period'] = 'month';
                              setClient({
                                ...client,
                                maintenance_info: {
                                  ...client?.maintenance_info,
                                  frequency: frequency,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <h2>Inclusion au contrat</h2>
          <div className="form-section">
            <div>
              <h3>Inclusion au contat</h3>
              <div className="full-space">
                <div className="dropdown-container border">
                  <div
                    onClick={() => setContractInclusionOpen((prev) => !prev)}
                  >
                    <label>Inclusion au contrat...</label>
                    <HiChevronDown size={26} className="clickable" />
                  </div>

                  {isContractInclusionOpen && (
                    <Dropdown
                      isDropdownOpen={isContractInclusionOpen}
                      setDropdownOpen={setContractInclusionOpen}
                      items={dropdownContractInclusion}
                      multiselect={true}
                    />
                  )}
                </div>
                <div>
                  <div className="chips-wrapper">
                    {client?.maintenance_info?.contract_inclusion?.map(
                      (item, index) => {
                        return (
                          <Chip
                            key={index}
                            text={item}
                            close
                            handleClick={() =>
                              setClient({
                                ...client,
                                maintenance_info: {
                                  ...client?.maintenance_info,
                                  contract_inclusion:
                                    client?.maintenance_info?.contract_inclusion?.filter(
                                      (el) => el !== item
                                    ),
                                },
                              })
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>

            {['Easiflo', 'Réparations Easiflo'].some((el) =>
              client?.maintenance_info?.contract_inclusion?.includes(el)
            ) && (
              <div>
                <h3>Type Easiflo</h3>
                <div>
                  <div className="dropdown-container border">
                    <div onClick={() => setEasifloOpen((prev) => !prev)}>
                      <label>
                        {client?.maintenance_info?.easiflo_type &&
                        client?.maintenance_info?.easiflo_type !== ''
                          ? client?.maintenance_info?.easiflo_type
                          : 'Type Easiflo...'}
                      </label>
                      <HiChevronDown size={26} className="clickable" />
                    </div>

                    {isEasifloOpen && (
                      <Dropdown
                        isDropdownOpen={isEasifloOpen}
                        setDropdownOpen={setEasifloOpen}
                        items={dropdownEasiflo}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            <div>
              <h3>Note</h3>
              <div>
                <Input
                  type="text"
                  placeholder="Note..."
                  value={client?.maintenance_info?.contract_inclusion_note}
                  id="maintenance_info"
                  name="contract_inclusion_note"
                  handleChange={(e) => handleTextChange(e)}
                />
              </div>
            </div>
          </div>

          <div className="form-button-wrapper">
            <SecondaryButton
              type="button"
              text="Annuler"
              handleClick={() => navigate(-1)}
            />
            <PrimaryButton
              type="submit"
              text="Enregistrer"
              isLoading={isLoading}
              loaderColor={white}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddClient;
