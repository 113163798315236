import React, { useEffect, useState } from 'react';
import Alert from '../../../components/generic/Alert';
import usePoolApi from '../../../api/usePoolApi';
import Card from '../../../components/generic/Card';
import Loader from '../../../components/generic/Loader';
import useColors from '../../../hooks/useColors';
import PrimaryButton from '../../../components/generic/PrimaryButton';

const PoolList = ({ id, setActiveTab, setSelectedPool }) => {
    const { getPoolList } = usePoolApi();
    const { primaryColor } = useColors();
    const [message, setMessage] = useState();
    const [pools, setPools] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getPoolList(id, response => {
            if (response?.status === 'success') {
                setPools(response?.content);
            } else {
                setMessage(response?.message);
            }
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <div className='loader-full-screen'><Loader color={primaryColor} size={'6rem'} /></div>;
    }

    return (
        <>
            {message && <Alert message={message} setMessage={setMessage} type='error' />}
            <div className='wrapper-content-right'>
                <PrimaryButton text='Ajouter une piscine' type='button' handleClick={() => setActiveTab(2)} />
            </div>
            <div className='card-wrapper'>
                {pools?.map((item, index) => {
                    return (
                        <Card key={index} item={item} handleClick={() => { setSelectedPool(item?.sub); setActiveTab(1); }} />
                    );
                })}
            </div>
        </>
    );
};

export default PoolList;