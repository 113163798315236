import React, { useRef, useEffect, useState } from 'react';

const Tabs = ({ items, activeTab, setActiveTab }) => {
  const tabRefs = useRef(items.map(() => React.createRef()));
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [activeTabOffset, setActiveTabOffset] = useState(0);

  useEffect(() => {
    if (tabRefs.current[activeTab]?.current) {
      const tabWidth = tabRefs.current[activeTab].current.offsetWidth;
      const tabOffset = tabRefs.current[activeTab].current.offsetLeft;
      setActiveTabWidth(tabWidth);
      setActiveTabOffset(tabOffset);
    }
  }, [activeTab]);

  const indicatorDynamicStyle = {
    width: activeTabWidth,
    transform: `translateX(${activeTabOffset}px)`,
  };

  return (
    <div className="tabs-container">
      {items?.map((item, index) => {
        return (
          <h3
            key={index}
            ref={tabRefs.current[index]}
            onClick={() => setActiveTab(index)}
          >
            {item}
          </h3>
        );
      })}
      <div className="active" style={indicatorDynamicStyle} />
    </div>
  );
};

export default Tabs;
