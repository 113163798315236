import { sendRequest } from '../utils/CommonMethods';

// useAuth.js
const useUserApi = () => {
  const getUser = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users/${sub}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getUserList = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users`;
    sendRequest(url, 'GET', null, callback);
  };

  const getUserListByRole = async (roles, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users/role/${roles}`;
    sendRequest(url, 'GET', null, callback);
  };

  const getArchivedUserList = async (callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users/archive/list`;
    sendRequest(url, 'GET', null, callback);
  };

  const updateUser = async (sub, staff, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users/${sub}`;
    sendRequest(url, 'PATCH', staff, callback);
  };

  const disableUser = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users/${sub}`;
    sendRequest(url, 'DELETE', null, callback);
  };

  const enableUser = async (sub, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users/${sub}`;
    sendRequest(url, 'PUT', null, callback);
  };

  const createUser = async (staff, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/users`;
    sendRequest(url, 'POST', staff, callback);
  };

  return {
    getUser,
    getUserList,
    getUserListByRole,
    getArchivedUserList,
    updateUser,
    disableUser,
    enableUser,
    createUser,
  };
};

export default useUserApi;
