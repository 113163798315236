export const sites = ['Vasta Piscine', 'Site 2', 'Site 3'];

export const sectors = ['Secteur 1', 'Secteur 2', 'Secteur 3'];

export const clientTypes = ['Copropriété', 'Particulier'];

export const easifloType = ['Type 1', 'Type 2', 'Type 3'];

export const access = [
  'Clé',
  'Bip',
  'Vigik',
  'Code',
  'Accès libre',
  'Appeler le contact sur place',
  `Se présenter à l'accueil`,
  'Autre',
];

export const inclusion = [
  'Produit de traitement',
  `Matériel d'entretien`,
  'Easiflo',
  'Réparations Easiflo',
  'Réparation annuelle (nettoyage du bassin)',
  'vidange annuelle (nettoyage des plages)',
  `Nettoyage du pédulive lors des visites d'entretien`,
  `Nettoyage des plages lors des visites d'entretien`,
  'Autre',
];

export const months = [
  'Jan',
  'Fév',
  'Mar',
  'Avr',
  'Mai',
  'Jun',
  'Jul',
  'Aou',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];

export const hours = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const duration = [
  '30',
  '60',
  '90',
  '120',
  '150',
  '180',
  '210',
  '240',
  '270',
  '300',
  '330',
  '360',
  '390',
  '420',
  '450',
  '480',
];

export const period = ['day', 'week', 'month'];

export const staffTableCells = ['Nom', 'Email', 'Téléphone', 'Secteur', 'Role'];

export const clientsTableCells = [
  'Nom',
  'Type',
  'Adresse',
  'GPS',
  'Technicien',
  'Secteur',
];

export const staffModel = {
  site: 'Vasta Piscine',
  photo: null,
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  password: '',
  confirm_paassword: '',
  roles: [],
  sector: '',
  car_immat: '',
  tele_ref: '',
  tshirt: '',
  pants: '',
  shoes: '',
};

export const clientModel = {
  site: 'Vasta Piscine',
  sector: '',
  user_sub: '',
  client_type: '',
  name: '',
  address: { address: '', zipcode: '', city: '' },
  contacts: [],
  gps: { longitude: '', latitude: '' },
  access: [],
  access_note: '',
  syndic: {
    name: '',
    address: { address: '', zipcode: '', city: '' },
    phone: '',
    email: '',
  },
  contract_date: '',
  maintenance_info: {
    days: [],
    hour: '',
    duration: '',
    frequency: [
      { month: '0', reccurence: '0', period: '' },
      { month: '1', reccurence: '0', period: '' },
      { month: '2', reccurence: '0', period: '' },
      { month: '3', reccurence: '0', period: '' },
      { month: '4', reccurence: '0', period: '' },
      { month: '5', reccurence: '0', period: '' },
      { month: '6', reccurence: '0', period: '' },
      { month: '7', reccurence: '0', period: '' },
      { month: '8', reccurence: '0', period: '' },
      { month: '9', reccurence: '0', period: '' },
      { month: '10', reccurence: '0', period: '' },
      { month: '11', reccurence: '0', period: '' },
    ],
    contract_inclusion: [],
    easiflo_type: '',
    contract_inclusion_note: '',
  },
};

export const poolModel = {
  photo: null,
  description: '',
  gps: { longitude: '', latitude: '' },
  bassin: {
    type_asspiration: { value: '', photo: null },
    type_revetement: { value: '', photo: null },
    refoulement: { value: '', photo: null },
    bonde_fond: { value: '', photo: null },
    prise_balais: { value: '', photo: null },
    projecteur: { value: '', photo: null },
    nage_contre_courant: { value: '', photo: null },
    echelle: [{ value: '', photo: null }],
    main_courante: [{ value: '', photo: '' }],
    alarme: [{ value: '', photo: null }],
    bache: { value: '', photo: null },
    volet_roulant: { value: '', photo: null },
    margelle: { value: '', photo: null },
    volume: { value: '', photo: null },
  },
  local_technique: {
    dimensions_canalisation: { value: '', photo: null },
    vannes: [{ value: '', photo: null }],
    vannes_motorisees: { value: '', photo: null },
    arrivee_electrique: { value: '', photo: null },
    pompe_filtration: [{ value: '', photo: null }],
    multivoie: [{ value: '', photo: null }],
    filtre: [{ value: '', photo: null }],
    traitement_chlore: [{ value: '', photo: null }],
    traitement_ph: [{ value: '', photo: null }],
    remplissage: { value: '', photo: null },
    info_vidange: { value: '', photo: null },
    compteur_eau: [{ value: '', photo: null }],
    minuterie_pedulive: { value: '', photo: null },
    pompe_chaleur: [{ value: '', photo: null }],
    vidange_remplissage: { value: '', photo: null },
  },
};
