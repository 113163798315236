import { useState } from 'react';
import Tabs from '../../components/generic/Tabs';
import MonthView from './MonthView';
import DayView from './DayView';
import PrimaryButton from '../../components/generic/PrimaryButton';
import { CalendarProvider } from './CalendarContext';
import { useNavigate } from 'react-router-dom';
import WeekView from './WeekView';

const tabs = ['Semaine', 'Mois', 'Jour'];

const Planning = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <CalendarProvider>
        <div>
          <div className="header">
            <h1>Planning</h1>
            <PrimaryButton
              text="Ajouter une intervention"
              type="button"
              handleClick={() => navigate('/planning/add')}
            />
          </div>
          <Tabs
            items={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className="page-wrapper">
          {activeTab === 0 && <WeekView setActiveTab={setActiveTab} />}
          {activeTab === 1 && <MonthView setActiveTab={setActiveTab} />}
          {activeTab === 2 && <DayView setActiveTab={setActiveTab} />}
        </div>
      </CalendarProvider>
    </>
  );
};

export default Planning;
