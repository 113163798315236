import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';

const Alert = ({ message, setMessage, type }) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setMessage();
        }, 5000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [setMessage]);

    return (
        <div className="alert">
            <span className={type}>{message}</span>
            <MdClose size={20} onClick={() => setMessage()} />
        </div>
    )
}

export default Alert;