// AuthContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import useUserApi from './api/useUserApi';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { getUser } = useUserApi();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser && isTokenValid(storedUser.expiration)) {
        await getUser(storedUser?.sub, (response) => {
          if (response?.status === 'success') {
            setUser({
              ...storedUser,
              fullname: `${response?.content?.firstname ?? ''} ${
                response?.content?.lastname ?? ''
              }`,
              roles: response?.content?.roles,
              photo: response?.content?.photo ?? null,
            });
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...storedUser,
                fullname: `${response?.content?.firstname ?? ''} ${
                  response?.content?.lastname ?? ''
                }`,
                roles: response?.content?.roles,
                photo: response?.content?.photo ?? null,
              })
            );
          } else {
            logoutSession();
          }
        });
      } else {
        logoutSession();
      }
      setIsLoading(false);
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTokenValid = (expiration) => {
    return expiration * 1000 > Date.now();
  };

  const loginSession = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    console.log(userData);

    getUser(userData?.sub, (response) => {
      if (response?.status === 'success') {
        setUser({
          ...userData,
          fullname: `${response?.content?.firstname ?? ''} ${
            response?.content?.lastname ?? ''
          }`,
          roles: response?.content?.roles,
          photo: response?.content?.photo ?? null,
        });
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...userData,
            fullname: `${response?.content?.firstname ?? ''} ${
              response?.content?.lastname ?? ''
            }`,
            roles: response?.content?.roles,
            photo: response?.content?.photo ?? null,
          })
        );
      } else {
        logoutSession();
      }
    });
  };

  const logoutSession = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const contextValue = {
    user,
    isLoading,
    loginSession,
    logoutSession,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
