import React, { useEffect, useState } from 'react';
import Table from '../../components/generic/Table';
import useUserApi from '../../api/useUserApi';
import Alert from '../../components/generic/Alert';
import { useNavigate } from 'react-router-dom';
import { staffTableCells } from '../../utils/StaticData';
import { formatData } from '../../utils/CommonMethods';

const StaffTab = () => {
    const navigate = useNavigate();
    const { getUserList } = useUserApi();
    const [staffList, setStaffList] = useState([]);
    const [message, setMessage] = useState();

    useEffect(() => {
        getUserList(response => {
            if (response?.status === 'success') {
                setStaffList(formatData('staff', response?.content, navigate));
            } else {
                setMessage(response?.message);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {message && <Alert message={message} setMessage={setMessage} type='error' />}
            <Table cells={staffTableCells} data={staffList} />
        </>
    );
};

export default StaffTab;