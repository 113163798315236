import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdChevronRight, MdOutlineReportProblem } from 'react-icons/md';
import { LuUsers2 } from 'react-icons/lu';
import { ImUsers, ImCalendar } from 'react-icons/im';

const drawerItems = [
  { icon: <ImCalendar />, text: 'Planning', link: '/planning' },
  { icon: <MdOutlineReportProblem />, text: 'Anomalies', link: '/anomalies' },
  { icon: <LuUsers2 />, text: 'Staff', link: '/staff' },
  { icon: <ImUsers />, text: 'Clients', link: '/clients' },
];

const Drawer = ({ isDrawerOpen, handleResize }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const index = drawerItems.findIndex((route) =>
      location.pathname.startsWith(route.link)
    );
    setActiveTab(index);
  }, [location.pathname]);

  const indicaotrDynamicStyles = {
    height: `${100 / drawerItems?.length}%`,
    transform: `translateY(${100 * activeTab}%)`,
  };

  return (
    <div className={`drawer ${isDrawerOpen ? 'show' : 'hide'}`}>
      <div className="drawer-resizer clickable">
        <MdChevronRight
          size={'2.5rem'}
          onClick={() => handleResize(!isDrawerOpen)}
        />
      </div>
      <ul>
        <div className="drawer-indicator" style={indicaotrDynamicStyles}></div>
        {drawerItems.map((item, index) => (
          <li key={index}>
            <Link
              className={`drawer-link`}
              to={item.link}
              onClick={() => setActiveTab(index)}
            >
              {item.icon}
              <span className="text">{item.text}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Drawer;
