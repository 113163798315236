import React, { useRef, useEffect } from 'react';
import { useNotifications } from '../NotificationContext';
import useNotificationApi from '../api/useNotificationApi';
import { MdMarkChatRead } from 'react-icons/md';
import useColors from '../hooks/useColors';
import { useNavigate } from 'react-router-dom';

const NotificationDropdown = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { notifications, setNotifications } = useNotifications();
  const dropdownRef = useRef(null);
  const { readNotification, readNotifications } = useNotificationApi();
  const { primaryColor } = useColors();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  const handleClick = (sub) => {
    readNotification(sub, (response) => {
      if (response?.status === 'success') {
        setNotifications(
          notifications.map((notification) =>
            notification.sub === sub
              ? { ...notification, status: 'read' }
              : notification
          )
        );
        onClose();
        navigate('anomalies');
      }
    });
  };

  const handleReadAllNotifications = () => {
    readNotifications((response) => {
      if (response?.status === 'success') {
        setNotifications(
          notifications.map((notification) => ({
            ...notification,
            status: 'read',
          }))
        );
      }
    });
  };

  return (
    <div className="notification-dropdown" ref={dropdownRef}>
      <div className="notification-dropdown-header">
        <h3>Notifications</h3>
        <MdMarkChatRead
          size={24}
          color={primaryColor}
          cursor="pointer"
          onClick={() => handleReadAllNotifications()}
        />
      </div>
      <div className="notification-dropdown-content">
        {notifications?.map((notification, index) => (
          <div
            key={index}
            className="notification-item"
            onClick={() => handleClick(notification.sub)}
            style={{
              backgroundColor:
                notification.status === 'read' ? 'white' : 'var(--light-gray)',
            }}
          >
            <div className="notification-text-container">
              <span className="notification-title">{notification.title}</span>
              <span className="notification-subtitle">
                {notification.message}
              </span>
            </div>
            <div className="notification-datetime-container">
              <span className="notification-datetime">
                {new Date(notification.sent_at).toLocaleDateString() ===
                new Date().toLocaleDateString()
                  ? new Date(notification.sent_at).toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })
                  : new Date(notification.sent_at).toLocaleDateString()}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationDropdown;
