import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useCalendar } from './CalendarContext';
import useInterventionApi from '../../api/useInterventionApi';
import useColors from '../../hooks/useColors';
// eslint-disable-next-line no-unused-vars
import { FaChevronLeft, FaChevronRight, FaFilter } from 'react-icons/fa';
import { MdToday } from 'react-icons/md';
import InterventionDrawer from './InterventionDrawer';
import { RiErrorWarningFill } from 'react-icons/ri';

const DayView = () => {
  const { selectedDay, setSelectedDay, goToToday } = useCalendar();
  const { getInterventionListByDate } = useInterventionApi();
  const { primaryColor, gray, white } = useColors();
  const [isLoading, setLoading] = useState(false);
  const [interventions, setInterventions] = useState([]);
  const [openInterventionDrawer, setOpenInterventionDrawer] = useState(false);
  const [selectedInterventionSub, setSelectedInterventionSub] = useState(null);

  useEffect(() => {
    setLoading(true);
    getInterventionListByDate(selectedDay, (response) => {
      if (response?.status === 'success') {
        const sortedContent = [...response.content].sort((a, b) => {
          const datetimeA = `${a.date} ${a.hour}`;
          const datetimeB = `${b.date} ${b.hour}`;
          return moment(datetimeA, 'YYYY-MM-DD HH:mm').diff(
            moment(datetimeB, 'YYYY-MM-DD HH:mm')
          );
        });
        setInterventions(sortedContent);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, openInterventionDrawer]);

  const handleTodayPress = () => {
    goToToday();
  };

  const goToNextDay = () => {
    setSelectedDay((prevDay) =>
      moment(prevDay).add(1, 'days').format('YYYY-MM-DD')
    );
  };

  const goToPrevDay = () => {
    setSelectedDay((prevDay) =>
      moment(prevDay).subtract(1, 'days').format('YYYY-MM-DD')
    );
  };

  const handleInterventionClick = (sub) => {
    setSelectedInterventionSub(sub);
    setOpenInterventionDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenInterventionDrawer(false);
    setSelectedInterventionSub(null);
  };

  return (
    <>
      {openInterventionDrawer && (
        <InterventionDrawer
          sub={selectedInterventionSub}
          handleCloseDrawer={handleCloseDrawer}
        />
      )}
      {isLoading && (
        <div className="loader-full-screen">
          <div className="loader-svg" />
        </div>
      )}
      <div className="calendarHeader">
        <button className="calendarHeaderButton">
          {/* <FaFilter size={30} color="black" /> */}
        </button>
        <button onClick={goToPrevDay} className="calendarHeaderButton">
          <FaChevronLeft size={40} color="black" />
        </button>
        <span className="calendarHeaderTitle">
          {moment(selectedDay).format('DD-MM-YYYY')}
        </span>
        <button onClick={goToNextDay} className="calendarHeaderButton">
          <FaChevronRight size={40} color="black" />
        </button>
        <button onClick={handleTodayPress} className="calendarHeaderButton">
          <MdToday size={30} color="black" />
        </button>
      </div>
      <div className="calendarContent">
        {interventions.length > 0 &&
          interventions.map((event, index) => (
            <div key={index}>
              {event.hour !== interventions[index - 1]?.hour &&
                event.hour &&
                index !== 0 && <div className="hourSeparator" />}
              <div key={index} className="eventsContainer">
                <div className="hourContainer">
                  <span
                    className="hour"
                    style={
                      event?.hour !== interventions?.[index - 1]?.hour
                        ? {}
                        : { color: 'transparent' }
                    }
                  >
                    {event.hour}
                  </span>
                </div>
                <div
                  className={`eventStateIndicator`}
                  style={{
                    backgroundColor:
                      event?.state === 'doing'
                        ? gray
                        : event?.state === 'done' && primaryColor,
                    borderColor: event?.state === 'done' ? primaryColor : gray,
                  }}
                />
                <div
                  className={`event`}
                  style={{
                    backgroundColor:
                      event?.state === 'doing'
                        ? gray
                        : event?.state === 'done' && primaryColor,
                    borderColor: event?.state === 'done' ? primaryColor : gray,
                    color:
                      (event?.state === 'done' || event?.state === 'doing') &&
                      white,
                  }}
                  onClick={() => handleInterventionClick(event.sub)}
                >
                  {(event?.stabilisant < '50' ||
                    event?.stabilisant > '70' ||
                    event?.ph < '6,9' ||
                    event?.ph > '7,7' ||
                    event?.cholre < '2' ||
                    event?.cholre > '5') && (
                    <div className="warning-badge" style={{ top: 0, right: 0 }}>
                      <RiErrorWarningFill size={24} color="red" />
                    </div>
                  )}
                  <div className="eventDetails">
                    <span className="eventTitle">
                      {event.pool.description} - {event.client.name}
                    </span>
                    <span className="eventTime">
                      {moment(
                        `${selectedDay} ${event.hour}`,
                        'YYYY-MM-DD HH:mm'
                      ).format('HH:mm')}{' '}
                      -
                      {moment(
                        `${selectedDay} ${event.hour}`,
                        'YYYY-MM-DD HH:mm'
                      )
                        .add(event.duration, 'minutes')
                        .format('HH:mm')}
                    </span>
                  </div>
                  <div
                    className="eventAvatar"
                    style={{
                      backgroundColor:
                        (event?.state === 'done' || event?.state === 'doing') &&
                        white,
                    }}
                  >
                    <span
                      className="eventAvatarText"
                      style={{
                        color:
                          event?.state === 'done'
                            ? primaryColor
                            : event?.state === 'doing' && gray,
                      }}
                    >
                      {event.tech.firstname.charAt(0)}
                      {event.tech.lastname.charAt(0)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default DayView;
