import React, { useState } from 'react';
import { MdAccountCircle } from 'react-icons/md';
import { HiChevronDown } from 'react-icons/hi';
import { useAuthContext } from '../AuthContext';
import Dropdown from './generic/Dropdown';
import LogoAppbar from '../assets/logos/logo-appbar.png';
import { FaBell } from 'react-icons/fa';
import { useNotifications } from '../NotificationContext';
import NotificationDropdown from './NotificationDropdown';

const AppBar = () => {
  const { logoutSession, user } = useAuthContext();
  const [isUserDropdownOpen, setUserDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setNotificationDropdownOpen] =
    useState(false);
  const { notifications } = useNotifications();

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!isUserDropdownOpen);
  };

  const handleLogout = () => {
    logoutSession();
  };

  const userDropdownItems = [{ label: 'Logout', action: handleLogout }];

  const upperCaseFirstLetter = (word) => {
    return word.replace(/\b\w/g, (match) => match.toUpperCase());
  };

  return (
    <div className="appbar">
      <div>
        <img src={LogoAppbar} alt="LogoAppbar" className="logo" />
      </div>

      <div></div>

      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <div className="notifications-appbar-container">
          <div
            onClick={() =>
              setNotificationDropdownOpen(!isNotificationDropdownOpen)
            }
          >
            <FaBell size={'2rem'} className="clickable" color="white" />
            {notifications.filter(
              (notification) => notification.status === 'sent'
            ).length > 0 && (
              <span className="notification-badge">
                {notifications.filter(
                  (notification) => notification.status === 'sent'
                ).length > 9
                  ? '9+'
                  : notifications.filter(
                      (notification) => notification.status === 'sent'
                    ).length}
              </span>
            )}
          </div>
          <NotificationDropdown
            isOpen={isNotificationDropdownOpen}
            onClose={() => setNotificationDropdownOpen(false)}
          />
        </div>
        <div className="dropdown-container" id="user-section">
          {user?.photo ? (
            <img src={user?.photo} alt="UserPhoto" />
          ) : (
            <MdAccountCircle size="3rem" />
          )}
          {window.innerWidth > 800 && (
            <div id="appbar-user-info">
              <h4>{user?.fullname ?? 'User Name'}</h4>
              <span>{upperCaseFirstLetter(user?.roles?.[0] ?? 'Roles')}</span>
            </div>
          )}
          <HiChevronDown
            size={26}
            className="clickable"
            onClick={toggleUserDropdown}
          />

          {isUserDropdownOpen && (
            <Dropdown
              isDropdownOpen={isUserDropdownOpen}
              setDropdownOpen={setUserDropdownOpen}
              items={userDropdownItems}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppBar;
