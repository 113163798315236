import React from 'react';
import { IoPersonCircleSharp } from 'react-icons/io5';
import useColors from '../../hooks/useColors';
import { Link } from 'react-router-dom';

const Table = ({ cells, data }) => {
    const { white } = useColors();

    return (
        <table className='table'>
            <thead className='table-header'>
                <tr>
                    {cells?.map((item, index) => {
                        return (
                            <th key={index}>{item}</th>
                        );
                    })}
                </tr>
            </thead>
            <tbody className='table-body'>
                {data?.map((item, index) => {
                    return (
                        <tr key={index}>
                            {item?.map((subItem, subIndex) => {
                                if (!['id', 'photo'].includes(subItem?.type)) {
                                    if (subItem?.type === 'name') {
                                        let photo = item?.filter(el => el?.type === 'photo' && el?.value !== null)?.[0]?.value;
                                        return (
                                            <td key={subIndex} className='clickable' onClick={() => subItem?.action()}>
                                                <div className='table-img-wrapper'>
                                                    {photo ?
                                                        <img src={photo} alt={subIndex} />
                                                        :
                                                        <IoPersonCircleSharp color={white} />
                                                    }
                                                </div>
                                                <div>
                                                    <span>{subItem?.value}</span>
                                                </div>
                                            </td>
                                        );
                                    } else if (['link'].includes(subItem?.type)) {
                                        return (
                                            <td
                                                key={subIndex}
                                            >
                                                <Link onClick={() => subItem?.action()}>{subItem?.value}</Link>
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td
                                                key={subIndex}
                                            >
                                                <span>{subItem?.value}</span>
                                            </td>
                                        );
                                    }
                                } else {
                                    return null;
                                }
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;