import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';

const CalendarContext = createContext();

export const useCalendar = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }) => {
  const [selectedDay, setSelectedDay] = useState(moment().format('YYYY-MM-DD'));

  const goToToday = () => {
    setSelectedDay(moment().format('YYYY-MM-DD'));
  };

  return (
    <CalendarContext.Provider
      value={{ selectedDay, setSelectedDay, goToToday }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
