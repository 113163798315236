import React, { useEffect, useState } from 'react';
import useInterventionApi from '../../api/useInterventionApi';
import Alert from '../../components/generic/Alert';
import { MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useClientApi from '../../api/useClientApi';
import RequiredAsterisk from '../../components/generic/RequitredAsterisk';
import { HiChevronDown } from 'react-icons/hi';
import Dropdown from '../../components/generic/Dropdown';
import usePoolApi from '../../api/usePoolApi';
import AlertDateTime from '../../components/generic/AletDateTime';
import SecondaryButton from '../../components/generic/SecondaryButton';
import PrimaryButton from '../../components/generic/PrimaryButton';
import useColors from '../../hooks/useColors';

const AddIntervention = () => {
  const navigate = useNavigate();
  const { white } = useColors();
  const [intervention, setIntervention] = useState({
    date: '',
    hour: '',
    pool_sub: '',
  });
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { addIntervention } = useInterventionApi();
  const { getClientList } = useClientApi();
  const { getPoolList } = usePoolApi();
  const [isClientOpen, setClientOpen] = useState(false);
  const [dropdownClients, setDropdownClients] = useState([]);
  const [pools, setPools] = useState([]);
  const [isPoolOpen, setPoolOpen] = useState(false);
  const [dropdownPools, setDropdownPools] = useState([]);
  const [
    isRescheduleInterventionAlertVisible,
    setRescheduleInterventionAlertVisible,
  ] = useState(false);

  useEffect(() => {
    setLoading(true);
    getClientList((response) => {
      if (response?.status === 'success') {
        setClients(response.content);
      } else {
        setMessage(response.message);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let res = [];
    // eslint-disable-next-line array-callback-return
    clients?.map((item) => {
      res.push({
        label: item?.name,
        sub: item?.sub,
        action: () => setSelectedClient(item),
      });
    });
    setDropdownClients(res);
  }, [clients]);

  useEffect(() => {
    if (selectedClient) {
      getPoolList(selectedClient?.sub, (response) => {
        if (response?.status === 'success') {
          setPools(response.content);
        } else {
          setMessage(response.message);
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    if (pools?.length > 0) {
      let res = [];
      // eslint-disable-next-line array-callback-return
      pools?.map((item) => {
        res.push({
          label: item?.description,
          sub: item?.sub,
          action: () =>
            setIntervention({ ...intervention, pool_sub: item?.sub }),
        });
      });
      setDropdownPools(res);
    } else {
      setDropdownPools([]);
      setIntervention({ ...intervention, pool_sub: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pools]);

  const handleSelectDate = (date, hour) => {
    setRescheduleInterventionAlertVisible(false);
    setIntervention({ ...intervention, date, hour });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    addIntervention(intervention, (response) => {
      if (response?.status === 'success') {
        navigate(-1);
      } else {
        setMessage(response.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      {message && (
        <Alert message={message} type="error" setMessage={setMessage} />
      )}
      {isRescheduleInterventionAlertVisible && (
        <AlertDateTime
          title="Séléctionner la date et l'heure"
          message="Choisir une date et heure pour l'intervention"
          handleClickConfirm={handleSelectDate}
          handleClickCancel={() => setRescheduleInterventionAlertVisible(false)}
        />
      )}
      <div>
        <div className="navigation-title">
          <MdChevronLeft
            size={'3rem'}
            className="clickable"
            onClick={() => navigate(-1)}
          />
          <h1>Ajouter une intervention</h1>
        </div>
      </div>
      <div className="page-wrapper">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-section">
            <div>
              <h3>
                Client <RequiredAsterisk />
              </h3>
              <div>
                <div className="dropdown-container border">
                  <div onClick={() => setClientOpen((prev) => !prev)}>
                    <label>
                      {selectedClient && selectedClient?.name !== ''
                        ? selectedClient?.name
                        : 'Sélectionner le client'}
                    </label>
                    <HiChevronDown
                      size={26}
                      className="clickable"
                      onClick={() => setClientOpen((prev) => !prev)}
                    />
                  </div>

                  {isClientOpen && (
                    <Dropdown
                      isDropdownOpen={isClientOpen}
                      setDropdownOpen={setClientOpen}
                      items={dropdownClients}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h3>
                Piscine <RequiredAsterisk />
              </h3>
              <div>
                <div className="dropdown-container border">
                  <div onClick={() => setPoolOpen((prev) => !prev)}>
                    <label>
                      {intervention?.pool && intervention?.pool !== ''
                        ? pools?.find(
                            (item) => item?.sub === intervention?.pool
                          )?.description
                        : 'Sélectionner la piscine'}
                    </label>
                    <HiChevronDown
                      size={26}
                      className="clickable"
                      onClick={() => setPoolOpen((prev) => !prev)}
                    />
                  </div>
                  {isPoolOpen && (
                    <Dropdown
                      isDropdownOpen={isPoolOpen}
                      setDropdownOpen={setPoolOpen}
                      items={dropdownPools}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h3>
                Date & heure <RequiredAsterisk />
              </h3>
              <div>
                <SecondaryButton
                  text={
                    intervention?.date &&
                    intervention?.date !== '' &&
                    intervention?.hour &&
                    intervention?.hour !== ''
                      ? `${intervention?.date} ${intervention?.hour}`
                      : "Sélectionner la date et l'heure"
                  }
                  type="button"
                  handleClick={() =>
                    setRescheduleInterventionAlertVisible(true)
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-button-wrapper">
            <SecondaryButton
              type="button"
              text="Annuler"
              handleClick={() => navigate(-1)}
            />
            <PrimaryButton
              type="submit"
              text="Enregistrer"
              isLoading={isLoading}
              loaderColor={white}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddIntervention;
