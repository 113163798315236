import { sendRequest } from '../utils/CommonMethods';

// useAuth.js
const useAuthApi = () => {
  const login = async (email, password, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/auth/login`;
    const body = { email, password };
    sendRequest(url, 'POST', body, callback);
  };

  const forgotPassword = async (email, callback) => {
    const url = `${process.env.REACT_APP_API_URL}/auth/reset_password`;
    const body = { email };
    sendRequest(url, 'POST', body, callback);
  };

  return {
    login,
    forgotPassword,
  };
};

export default useAuthApi;
