import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoggedInRoutes from './routes/LoggedInRoutes';
import LoggedOutRoutes from './routes/LoggedOutRoutes';
import './App.css';
import { useAuthContext } from './AuthContext';
import Loader from './components/generic/Loader';
import useColors from './hooks/useColors';
import { NotificationProvider } from './NotificationContext';

function App() {
  const { user, isLoading } = useAuthContext();
  const { primaryColor } = useColors();

  if (isLoading) {
    return (
      <div className="loader-full-screen">
        <Loader color={primaryColor} size={'6rem'} />
      </div>
    );
  }

  return (
    <NotificationProvider>
      <Router>
        {user ? (
          <LoggedInRoutes user={user} />
        ) : (
          <LoggedOutRoutes user={user} />
        )}
      </Router>
    </NotificationProvider>
  );
}

export default App;
