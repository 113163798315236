import { compressImage } from '../components/generic/PhotoInput';

export const formatData = (type, items, navigate) => {
  let res = [];
  switch (type) {
    case 'staff':
      // eslint-disable-next-line array-callback-return
      items?.map((item) => {
        res.push([
          { value: item?.sub ?? null, type: 'id' },
          { value: item?.photo ?? null, type: 'photo' },
          {
            value: `${item?.firstname ?? ''} ${item?.lastname ?? ''}`,
            type: 'name',
            action: () => navigate(`/staff/${item?.sub}`),
          },
          {
            value: item?.email ?? '',
            type: 'link',
            action: () => (window.location.href = `mailto:${item?.email}`),
          },
          { value: item?.phone ?? '', type: 'text' },
          { value: item?.sector ?? '', type: 'text' },
          { value: item?.roles?.[0]?.toUpperCase() ?? '', type: 'text' },
        ]);
      });
      break;
    case 'clients':
      // eslint-disable-next-line array-callback-return
      items?.map((item) => {
        res.push([
          { value: item?.sub ?? null, type: 'id' },
          { value: item?.photo ?? null, type: 'photo' },
          {
            value: item?.name ?? '',
            type: 'name',
            action: () =>
              navigate(`/clients/${item?.sub}`, {
                state: { name: item?.name },
              }),
          },
          { value: item?.client_type ?? '', type: 'text' },
          {
            value: `${item?.address?.address ?? ''} ${
              item?.address?.city ?? ''
            } ${item?.address?.zipcode ?? ''}`,
            type: 'text',
          },
          {
            value: `${item?.gps?.latitude}, ${item?.gps?.longitude}` ?? '',
            type: 'link',
            action: () =>
              window.open(
                `https://www.google.com/maps?q=${item?.gps?.latitude},${item?.gps?.longitude}`,
                '_blank',
                'noopener,noreferrer'
              ),
          },
          {
            value: `${item?.tech?.[0]?.firstname ?? ''} ${
              item?.tech?.[0]?.lastname ?? ''
            }`,
            type: 'text',
          },
          { value: item?.sector ?? '', type: 'text' },
        ]);
      });
      break;
    default:
      break;
  }
  return res;
};

export const clientTextChange = (e, client, setClient) => {
  switch (e.target.id) {
    case 'address':
      setClient({
        ...client,
        address: { ...client?.address, [e.target.name]: e.target.value },
      });
      break;
    case 'gps':
      setClient({
        ...client,
        gps: { ...client?.gps, [e.target.name]: e.target.value },
      });
      break;
    case 'syndic':
      setClient({
        ...client,
        syndic: { ...client?.syndic, [e.target.name]: e.target.value },
      });
      break;
    case 'syndic.address':
      setClient({
        ...client,
        syndic: {
          ...client?.syndic,
          address: {
            ...client?.syndic?.address,
            [e.target.name]: e.target.value,
          },
        },
      });
      break;
    case 'contacts':
      let [contactsKey, contactsIndex] = e.target.name.split('-');
      let contacts = client?.contacts;
      contacts[contactsIndex][contactsKey] = e.target.value;
      setClient({ ...client, contacts: contacts });
      break;
    case 'maintenance_info.frequency':
      let [frequencyKey, frequencyIndex] = e.target.name.split('-');
      let frequency = client?.maintenance_info?.frequency;
      frequency[frequencyIndex][frequencyKey] = e.target.value;
      setClient({
        ...client,
        maintenance_info: { ...client?.maintenance_info, frequency: frequency },
      });
      break;
    case 'maintenance_info':
      setClient({
        ...client,
        maintenance_info: {
          ...client?.maintenance_info,
          [e.target.name]: [e.target.value],
        },
      });
      break;
    default:
      setClient({ ...client, [e.target.name]: e.target.value });
      break;
  }
};

export const initDropdownItems = (data, action, setState) => {
  let res = [];
  // eslint-disable-next-line array-callback-return
  data?.map((item) => {
    res.push({ label: item, action: () => action(item) });
  });
  setState(res);
};

export const poolTextChange = (e, pool, setPool) => {
  switch (e.target.id) {
    case 'gps':
      setPool({
        ...pool,
        gps: { ...pool?.gps, [e.target.name]: e.target.value },
      });
      break;
    case 'type_asspiration':
    case 'type_revetement':
    case 'refoulement':
    case 'bonde_fond':
    case 'prise_balais':
    case 'projecteur':
    case 'nage_contre_courant':
    case 'bache':
    case 'volet_roulant':
    case 'margelle':
    case 'volume':
      setPool({
        ...pool,
        bassin: {
          ...pool?.bassin,
          [e.target.name]: {
            ...pool?.bassin?.[e.target.name],
            value: e.target.value,
          },
        },
      });
      break;
    case 'dimensions_canalisation':
    case 'vannes_motorisees':
    case 'arrivee_electrique':
    case 'remplissage':
    case 'info_vidange':
    case 'minuterie_pedulive':
    case 'vidange_remplissage':
      setPool({
        ...pool,
        local_technique: {
          ...pool?.local_technique,
          [e.target.name]: {
            ...pool?.local_technique?.[e.target.name],
            value: e.target.value,
          },
        },
      });
      break;
    case 'echelle':
    case 'main_courante':
    case 'alarme':
      let [bKey, bIndex] = e.target.name.split('-');
      let bArr = pool?.bassin?.[bKey];
      bArr[bIndex]['value'] = e.target.value;
      setPool({ ...pool, bassin: { ...pool?.bassin, [bKey]: bArr } });
      break;
    case 'vannes':
    case 'pompe_filtration':
    case 'multivoie':
    case 'filtre':
    case 'traitement_chlore':
    case 'traitement_ph':
    case 'compteur_eau':
    case 'pompe_chaleur':
      let [ltKey, ltIndex] = e.target.name.split('-');
      let ltArr = pool?.local_technique?.[ltKey];
      ltArr[ltIndex]['value'] = e.target.value;
      setPool({
        ...pool,
        local_technique: { ...pool?.local_technique, [ltKey]: ltArr },
      });
      break;
    default:
      setPool({ ...pool, [e.target.name]: e.target.value });
      break;
  }
};

export const poolFileInputChange = async (event, itemKey, pool, setPool) => {
  let key, index;

  try {
    [key, index] = itemKey.split('-');
  } catch {
    key = itemKey;
  }

  const file = event.target.files[0];
  if (!file) return;

  try {
    const compressedFile = await compressImage(file);
    switch (key) {
      case 'type_asspiration':
      case 'type_revetement':
      case 'refoulement':
      case 'bonde_fond':
      case 'prise_balais':
      case 'projecteur':
      case 'nage_contre_courant':
      case 'bache':
      case 'volet_roulant':
      case 'margelle':
      case 'volume':
        setPool({
          ...pool,
          bassin: {
            ...pool?.bassin,
            [key]: { ...pool?.bassin?.[key], photo: compressedFile },
          },
        });
        break;
      case 'dimensions_canalisation':
      case 'vannes_motorisees':
      case 'arrivee_electrique':
      case 'remplissage':
      case 'info_vidange':
      case 'minuterie_pedulive':
      case 'vidange_remplissage':
        setPool({
          ...pool,
          local_technique: {
            ...pool?.local_technique,
            [key]: { ...pool?.local_technique?.[key], photo: compressedFile },
          },
        });
        break;
      case 'echelle':
      case 'main_courante':
      case 'alarme':
        let bArr = pool?.bassin?.[key];
        bArr[index]['photo'] = compressedFile;
        setPool({ ...pool, bassin: { ...pool?.bassin, [key]: bArr } });
        break;
      case 'vannes':
      case 'pompe_filtration':
      case 'multivoie':
      case 'filtre':
      case 'traitement_chlore':
      case 'traitement_ph':
      case 'compteur_eau':
      case 'pompe_chaleur':
        let ltArr = pool?.local_technique?.[key];
        ltArr[index]['photo'] = compressedFile;
        setPool({
          ...pool,
          local_technique: { ...pool?.local_technique, [key]: ltArr },
        });
        break;
      default:
        setPool({ ...pool, photo: compressedFile });
        break;
    }
  } catch (error) {
    console.error('Error compressing image:', error);
  }
};

export const getToken = async () =>
  JSON.parse(localStorage.getItem('user'))?.auth;

export const sendRequest = async (url, method, body, callback) => {
  const authToken = await getToken();
  let requestParams = {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
    method,
  };
  if (method !== 'GET' && method !== 'DELETE' && body) {
    requestParams.body = JSON.stringify(body);
  }
  fetch(url, requestParams)
    .then((response) => {
      if (!response.ok) {
        console.error(response);
      }
      return response.json();
    })
    .then((json) => {
      console.debug(json);
      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
};
