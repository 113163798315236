import React, { useState } from 'react';
import Alert from '../../../components/generic/Alert';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import PrimaryButton from '../../../components/generic/PrimaryButton';
import RemoveButton from '../../../components/generic/RemoveButton';
import SecondaryButton from '../../../components/generic/SecondaryButton';
import usePoolApi from '../../../api/usePoolApi';
import useColors from '../../../hooks/useColors';
import { poolModel } from '../../../utils/StaticData';
import PhotoInput from '../../../components/generic/PhotoInput';
import RequiredAsterisk from '../../../components/generic/RequitredAsterisk';
import Input from '../../../components/generic/Input';
import { poolFileInputChange, poolTextChange } from '../../../utils/CommonMethods';

const AddPool = ({ id, setActiveTab }) => {
    const { createPool } = usePoolApi();
    const { white } = useColors();
    const [pool, setPool] = useState(poolModel);
    const [message, setMessage] = useState();
    const [isLoading, setLoading] = useState(false);

    const handleTextChange = (e) => {
        poolTextChange(e, pool, setPool);
    };

    const handleFileInputChange = async (event, itemKey) => {
        await poolFileInputChange(event, itemKey, pool, setPool);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        createPool(id, pool, response => {
            if (response?.status === 'success') {
                setActiveTab(0);
            } else {
                setMessage(response?.message);
            }
            setLoading(false);
        });
    };

    return (
        <>
            {message && <Alert message={message} setMessage={setMessage} type='error' />}
            <div className='navigation-title'>
                <MdChevronLeft size={'3rem'} className='clickable' onClick={() => setActiveTab(0)} />
                <h1>Ajouter une piscine</h1>
            </div>
            <div className='page-wrapper'>
                <form className='form' onSubmit={handleSubmit}>
                    <h2>Piscine</h2>
                    <div className='form-section'>
                        <div>
                            <h3>Photo</h3>
                            <div>
                                {pool?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, photo: null })} />
                                        <img src={pool?.photo} alt='poolPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={handleFileInputChange} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Description <RequiredAsterisk /></h3>
                            <div>
                                <Input required={true} type='text' placeholder='Description...' value={pool?.description} id='description' name='description' handleChange={(e) => handleTextChange(e)} />
                            </div>
                        </div>

                        <div>
                            <h3>Coordonnées GPS</h3>
                            <div>
                                <div>
                                    <label>Latitude</label>
                                    <Input type='text' placeholder='Latitude...' value={pool?.gps?.latitude} id='gps' name='latitude' handleChange={(e) => handleTextChange(e)} />
                                </div>
                                <div>
                                    <label>Longitude</label>
                                    <Input type='text' placeholder='Longitude...' value={pool?.gps?.longitude} id='gps' name='longitude' handleChange={(e) => handleTextChange(e)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2>Bassin</h2>
                    <div className='form-section'>
                        <div>
                            <h3>Type d'asspiration</h3>
                            <div>
                                <Input type='text' placeholder="Type d'asspiration..." value={pool?.bassin?.type_asspiration?.value} id='type_asspiration' name='type_asspiration' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.type_asspiration?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, type_asspiration: { ...pool?.bassin?.type_asspiration, photo: null } } })} />
                                        <img src={pool?.bassin?.type_asspiration?.photo} alt='typeAsspirationPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'type_asspiration')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Type de revetement</h3>
                            <div>
                                <Input type='text' placeholder="Type de revetement..." value={pool?.bassin?.type_revetement?.value} id='type_revetement' name='type_revetement' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.type_revetement?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, type_revetement: { ...pool?.bassin?.type_revetement, photo: null } } })} />
                                        <img src={pool?.bassin?.type_revetement?.photo} alt='typeRevetementPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'type_revetement')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Refoulement</h3>
                            <div>
                                <Input type='text' placeholder="Refoulement..." value={pool?.bassin?.refoulement?.value} id='refoulement' name='refoulement' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.refoulement?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, refoulement: { ...pool?.bassin?.refoulement, photo: null } } })} />
                                        <img src={pool?.bassin?.refoulement?.photo} alt='refoulementPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'refoulement')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Bonde de fond</h3>
                            <div>
                                <Input type='text' placeholder="Bonde de fond..." value={pool?.bassin?.bonde_fond?.value} id='bonde_fond' name='bonde_fond' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.bonde_fond?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, bonde_fond: { ...pool?.bassin?.bonde_fond, photo: null } } })} />
                                        <img src={pool?.bassin?.bonde_fond?.photo} alt='bondeFondPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'bonde_fond')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Prise balais</h3>
                            <div>
                                <Input type='text' placeholder="Prise balais..." value={pool?.bassin?.prise_balais?.value} id='prise_balais' name='prise_balais' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.prise_balais?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, prise_balais: { ...pool?.bassin?.prise_balais, photo: null } } })} />
                                        <img src={pool?.bassin?.prise_balais?.photo} alt='priseBalaisPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'prise_balais')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Projecteurs</h3>
                            <div>
                                <Input type='text' placeholder="Projecteurs..." value={pool?.bassin?.projecteur?.value} id='projecteur' name='projecteur' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.projecteur?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, projecteur: { ...pool?.bassin?.projecteur, photo: null } } })} />
                                        <img src={pool?.bassin?.projecteur?.photo} alt='projecteurPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'projecteur')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Nage à contre-courant</h3>
                            <div>
                                <Input type='text' placeholder="Nage à contre-courant..." value={pool?.bassin?.nage_contre_courant?.value} id='nage_contre_courant' name='nage_contre_courant' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.nage_contre_courant?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, nage_contre_courant: { ...pool?.bassin?.nage_contre_courant, photo: null } } })} />
                                        <img src={pool?.bassin?.nage_contre_courant?.photo} alt='nageContreCourantPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'nage_contre_courant')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Echelle</h3>
                            <div>
                                {pool?.bassin?.echelle?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Echelle..." value={item.value} id='echelle' name={`echelle-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let echelle = pool?.bassin?.echelle;
                                                            echelle[index]['photo'] = null;
                                                            setPool({ ...pool, bassin: { ...pool?.bassin, echelle: echelle } });
                                                        }} />
                                                    <img src={item.photo} alt={`echellePhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `echelle-${index}`)} />
                                            }
                                            {pool?.bassin?.echelle?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, echelle: pool?.bassin?.echelle?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, echelle: [...pool?.bassin?.echelle, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Main courante</h3>
                            <div>
                                {pool?.bassin?.main_courante?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Main courante..." value={item.value} id='main_courante' name={`main_courante-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let main_courante = pool?.bassin?.main_courante;
                                                            main_courante[index]['photo'] = null;
                                                            setPool({ ...pool, bassin: { ...pool?.bassin, main_courante: main_courante } });
                                                        }} />
                                                    <img src={item.photo} alt={`mainCourantePhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `main_courante-${index}`)} />
                                            }
                                            {pool?.bassin?.main_courante?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, main_courante: pool?.bassin?.main_courante?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, main_courante: [...pool?.bassin?.main_courante, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Alarme</h3>
                            <div>
                                {pool?.bassin?.alarme?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Alarme..." value={item.value} id='alarme' name={`alarme-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let alarme = pool?.bassin?.alarme;
                                                            alarme[index]['photo'] = null;
                                                            setPool({ ...pool, bassin: { ...pool?.bassin, alarme: alarme } });
                                                        }} />
                                                    <img src={item.photo} alt={`alarmePhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `alarme-${index}`)} />
                                            }
                                            {pool?.bassin?.alarme?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, alarme: pool?.bassin?.alarme?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, alarme: [...pool?.bassin?.alarme, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Bâche</h3>
                            <div>
                                <Input type='text' placeholder="Bâche..." value={pool?.bassin?.bache?.value} id='bache' name='bache' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.bache?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, bache: { ...pool?.bassin?.bache, photo: null } } })} />
                                        <img src={pool?.bassin?.bache?.photo} alt='bachePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'bache')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Volet roulant</h3>
                            <div>
                                <Input type='text' placeholder="Volet roulant..." value={pool?.bassin?.volet_roulant?.value} id='volet_roulant' name='volet_roulant' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.volet_roulant?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, volet_roulant: { ...pool?.bassin?.volet_roulant, photo: null } } })} />
                                        <img src={pool?.bassin?.volet_roulant?.photo} alt='voletRoulantPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'volet_roulant')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Margelle</h3>
                            <div>
                                <Input type='text' placeholder="Margelle..." value={pool?.bassin?.margelle?.value} id='margelle' name='margelle' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.margelle?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, margelle: { ...pool?.bassin?.margelle, photo: null } } })} />
                                        <img src={pool?.bassin?.margelle?.photo} alt='margellePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'margelle')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Volume</h3>
                            <div>
                                <Input type='text' placeholder="Volume..." value={pool?.bassin?.volume?.value} id='volume' name='volume' handleChange={(e) => handleTextChange(e)} />
                                {pool?.bassin?.volume?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, bassin: { ...pool?.bassin, volume: { ...pool?.bassin?.volume, photo: null } } })} />
                                        <img src={pool?.bassin?.volume?.photo} alt='volumePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'volume')} />
                                }
                            </div>
                        </div>
                    </div>

                    <h2>Local technique</h2>
                    <div className='form-section'>
                        <div>
                            <h3>Dimensions canalisations</h3>
                            <div>
                                <Input type='text' placeholder="Dimensions canalisations..." value={pool?.local_technique?.dimensions_canalisation?.value} id='dimensions_canalisation' name='dimensions_canalisation' handleChange={(e) => handleTextChange(e)} />
                                {pool?.local_technique?.dimensions_canalisation?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, dimensions_canalisation: { ...pool?.local_technique?.dimensions_canalisation, photo: null } } })} />
                                        <img src={pool?.local_technique?.dimensions_canalisation?.photo} alt='dimensionsCanalisationPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'dimensions_canalisation')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Vannes</h3>
                            <div>
                                {pool?.local_technique?.vannes?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Vannes..." value={item.value} id='vannes' name={`vannes-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let vannes = pool?.local_technique?.vannes;
                                                            vannes[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, vannes: vannes } });
                                                        }} />
                                                    <img src={item.photo} alt={`vannesPhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `vannes-${index}`)} />
                                            }
                                            {pool?.local_technique?.vannes?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, vannes: pool?.local_technique?.vannes?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, vannes: [...pool?.local_technique?.vannes, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Vannes motorisées</h3>
                            <div>
                                <Input type='text' placeholder="Vannes motorisées..." value={pool?.local_technique?.vannes_motorisees?.value} id='vannes_motorisees' name='vannes_motorisees' handleChange={(e) => handleTextChange(e)} />
                                {pool?.local_technique?.vannes_motorisees?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, vannes_motorisees: { ...pool?.local_technique?.vannes_motorisees, photo: null } } })} />
                                        <img src={pool?.local_technique?.vannes_motorisees?.photo} alt='vannesMotoriseesPhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'vannes_motorisees')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Arrivée électrique</h3>
                            <div>
                                <Input type='text' placeholder="Arrivée électrique..." value={pool?.local_technique?.arrivee_electrique?.value} id='arrivee_electrique' name='arrivee_electrique' handleChange={(e) => handleTextChange(e)} />
                                {pool?.local_technique?.arrivee_electrique?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, arrivee_electrique: { ...pool?.local_technique?.arrivee_electrique, photo: null } } })} />
                                        <img src={pool?.local_technique?.arrivee_electrique?.photo} alt='arriveeElectriquePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'arrivee_electrique')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Pompe de filtration</h3>
                            <div>
                                {pool?.local_technique?.pompe_filtration?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Pompe de filtration..." value={item.value} id='pompe_filtration' name={`pompe_filtration-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let pompe_filtration = pool?.local_technique?.pompe_filtration;
                                                            pompe_filtration[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, pompe_filtration: pompe_filtration } });
                                                        }} />
                                                    <img src={item.photo} alt={`pompeFiltrationPhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `pompe_filtration-${index}`)} />
                                            }
                                            {pool?.local_technique?.pompe_filtration?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, pompe_filtration: pool?.local_technique?.pompe_filtration?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, pompe_filtration: [...pool?.local_technique?.pompe_filtration, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Multivoie</h3>
                            <div>
                                {pool?.local_technique?.multivoie?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Multivoie..." value={item.value} id='multivoie' name={`multivoie-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let multivoie = pool?.local_technique?.multivoie;
                                                            multivoie[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, multivoie: multivoie } });
                                                        }} />
                                                    <img src={item.photo} alt={`multivoiePhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `multivoie-${index}`)} />
                                            }
                                            {pool?.local_technique?.multivoie?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, multivoie: pool?.local_technique?.multivoie?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, multivoie: [...pool?.local_technique?.multivoie, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Filtre</h3>
                            <div>
                                {pool?.local_technique?.filtre?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Filtre..." value={item.value} id='filtre' name={`filtre-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let filtre = pool?.local_technique?.filtre;
                                                            filtre[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, filtre: filtre } });
                                                        }} />
                                                    <img src={item.photo} alt={`filtrePhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `filtre-${index}`)} />
                                            }
                                            {pool?.local_technique?.filtre?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, filtre: pool?.local_technique?.filtre?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, filtre: [...pool?.local_technique?.filtre, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Système de traitement de chlore</h3>
                            <div>
                                {pool?.local_technique?.traitement_chlore?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Système de traitement de chlore..." value={item.value} id='traitement_chlore' name={`traitement_chlore-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let traitement_chlore = pool?.local_technique?.traitement_chlore;
                                                            traitement_chlore[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, traitement_chlore: traitement_chlore } });
                                                        }} />
                                                    <img src={item.photo} alt={`traitementChlorePhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `traitement_chlore-${index}`)} />
                                            }
                                            {pool?.local_technique?.traitement_chlore?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, traitement_chlore: pool?.local_technique?.traitement_chlore?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, traitement_chlore: [...pool?.local_technique?.traitement_chlore, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Système de traitement de PH</h3>
                            <div>
                                {pool?.local_technique?.traitement_ph?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Système de traitement de PH..." value={item.value} id='traitement_ph' name={`traitement_ph-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let traitement_ph = pool?.local_technique?.traitement_ph;
                                                            traitement_ph[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, traitement_ph: traitement_ph } });
                                                        }} />
                                                    <img src={item.photo} alt={`traitementPHPhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `traitement_ph-${index}`)} />
                                            }
                                            {pool?.local_technique?.traitement_ph?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, traitement_ph: pool?.local_technique?.traitement_ph?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, traitement_ph: [...pool?.local_technique?.traitement_ph, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Remplissage</h3>
                            <div>
                                <Input type='text' placeholder="Remplissage..." value={pool?.local_technique?.remplissage?.value} id='remplissage' name='remplissage' handleChange={(e) => handleTextChange(e)} />
                                {pool?.local_technique?.remplissage?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, remplissage: { ...pool?.local_technique?.remplissage, photo: null } } })} />
                                        <img src={pool?.local_technique?.remplissage?.photo} alt='remplissagePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'remplissage')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Info vidange</h3>
                            <div>
                                <Input type='text' placeholder="Info vidange..." value={pool?.local_technique?.info_vidange?.value} id='info_vidange' name='info_vidange' handleChange={(e) => handleTextChange(e)} />
                                {pool?.local_technique?.info_vidange?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, info_vidange: { ...pool?.local_technique?.info_vidange, photo: null } } })} />
                                        <img src={pool?.local_technique?.info_vidange?.photo} alt='infoVidangePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'info_vidange')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Compteur d'eau</h3>
                            <div>
                                {pool?.local_technique?.compteur_eau?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Compteur d'eau..." value={item.value} id='compteur_eau' name={`compteur_eau-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let compteur_eau = pool?.local_technique?.compteur_eau;
                                                            compteur_eau[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, compteur_eau: compteur_eau } });
                                                        }} />
                                                    <img src={item.photo} alt={`compteurEauPhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `compteur_eau-${index}`)} />
                                            }
                                            {pool?.local_technique?.compteur_eau?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, compteur_eau: pool?.local_technique?.compteur_eau?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, compteur_eau: [...pool?.local_technique?.compteur_eau, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Minuterie pédulive</h3>
                            <div>
                                <Input type='text' placeholder="Minuterie pédulive..." value={pool?.local_technique?.minuterie_pedulive?.value} id='minuterie_pedulive' name='minuterie_pedulive' handleChange={(e) => handleTextChange(e)} />
                                {pool?.local_technique?.minuterie_pedulive?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, minuterie_pedulive: { ...pool?.local_technique?.minuterie_pedulive, photo: null } } })} />
                                        <img src={pool?.local_technique?.minuterie_pedulive?.photo} alt='minuteriePedulivePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'minuterie_pedulive')} />
                                }
                            </div>
                        </div>

                        <div>
                            <h3>Pompe à chaleur</h3>
                            <div>
                                {pool?.local_technique?.pompe_chaleur?.map((item, index) => {
                                    return (
                                        <>
                                            <Input type='text' placeholder="Pompe à chaleur..." value={item.value} id='pompe_chaleur' name={`pompe_chaleur-${index}`} handleChange={(e) => handleTextChange(e)} />
                                            {item.photo ?
                                                <div className='photo-preview'>
                                                    <MdClose
                                                        className='remove-icon'
                                                        color={white}
                                                        size='1rem'
                                                        onClick={() => {
                                                            let pompe_chaleur = pool?.local_technique?.pompe_chaleur;
                                                            pompe_chaleur[index]['photo'] = null;
                                                            setPool({ ...pool, local_technique: { ...pool?.local_technique, pompe_chaleur: pompe_chaleur } });
                                                        }} />
                                                    <img src={item.photo} alt={`pompeChaleurPhoto${index}`} />
                                                </div>
                                                :
                                                <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, `pompe_chaleur-${index}`)} />
                                            }
                                            {pool?.local_technique?.pompe_chaleur?.length > 1 && <RemoveButton text='Retirer' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, pompe_chaleur: pool?.local_technique?.pompe_chaleur?.filter((_, i) => i !== index) } })} />}
                                        </>
                                    );
                                })}
                            </div>
                            <div></div>
                            <div>
                                <PrimaryButton text='Ajouter' type='button' handleClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, pompe_chaleur: [...pool?.local_technique?.pompe_chaleur, { value: '', photo: null }] } })} />
                            </div>
                        </div>

                        <div>
                            <h3>Vidange/Remplissage</h3>
                            <div>
                                <Input type='text' placeholder="Vidange/Remplissage..." value={pool?.local_technique?.vidange_remplissage?.value} id='vidange_remplissage' name='vidange_remplissage' handleChange={(e) => handleTextChange(e)} />
                                {pool?.local_technique?.vidange_remplissage?.photo ?
                                    <div className='photo-preview'>
                                        <MdClose className='remove-icon' color={white} size='1rem' onClick={() => setPool({ ...pool, local_technique: { ...pool?.local_technique, vidange_remplissage: { ...pool?.local_technique?.vidange_remplissage, photo: null } } })} />
                                        <img src={pool?.local_technique?.vidange_remplissage?.photo} alt='vidangeRemplissagePhoto' />
                                    </div>
                                    :
                                    <PhotoInput handleDragOver={handleDragOver} handleFileInputChange={(e) => handleFileInputChange(e, 'vidange_remplissage')} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='form-button-wrapper'>
                        <SecondaryButton type='button' text='Annuler' handleClick={() => setActiveTab(0)} />
                        <PrimaryButton type='submit' text='Enregistrer' isLoading={isLoading} loaderColor={white} />
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddPool;