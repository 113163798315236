/* const primary-color = '#00ABD8';
  --secondary-color: #7C7C7C;
  --dark-gray: #626266;
  --gray: #999999;
  --light-gray: #DADADA;
  --very-light-gray: #E9E9E9;
  --red: #E92C2C;
  --green: #5E9A37;
  --light-pink: #FAD3CB; */
// AuthContext.js
// useAuth.js
const useColors = () => {
  const primaryColor = '#00ABD8';
  const secondaryColor = '#7C7C7C';
  const darkGray = '#626266';
  const gray = '#999999';
  const lightGray = '#DADADA';
  const veryLightGray = '#E9E9E9';
  const red = '#E92C2C';
  const green = '#5E9A37';
  const lightPink = '#FAD3CB';
  const white = '#FFFFFF';

  return {
    primaryColor,
    secondaryColor,
    darkGray,
    gray,
    lightGray,
    veryLightGray,
    red,
    green,
    lightPink,
    white
  };
};

export default useColors;
