import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import useColors from '../../hooks/useColors';
import { days, months } from '../../utils/StaticData';
import { useCalendar } from './CalendarContext';
// eslint-disable-next-line no-unused-vars
import { FaFilter, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MdToday } from 'react-icons/md';
import { RiErrorWarningFill } from 'react-icons/ri';
import Alert from '../../components/generic/Alert';
import useInterventionApi from '../../api/useInterventionApi';
import Loader from '../../components/generic/Loader';

moment.locale('fr');

const MonthView = ({ setActiveTab }) => {
  const { primaryColor, gray } = useColors();
  const { selectedDay, setSelectedDay, goToToday } = useCalendar();
  const { getInterventionListByMonth } = useInterventionApi();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [interventions, setInterventions] = useState([]);
  const selectedMoment = moment(selectedDay, 'YYYY-MM-DD');
  const startOfMonth = selectedMoment.clone().startOf('month').day();
  const daysInMonth = selectedMoment.daysInMonth();
  const startDayOffset = startOfMonth === 0 ? 6 : startOfMonth - 1;

  useEffect(() => {
    setLoading(true);
    getInterventionListByMonth(
      moment(selectedDay).format('YYYY-MM'),
      (response) => {
        if (response?.status === 'success') {
          setInterventions(response?.content);
        } else {
          setMessage(response?.message);
        }
        setLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  const paddedDays = Array.from({ length: startDayOffset }, () => null).concat(
    Array.from({ length: daysInMonth }, (_, i) => i + 1)
  );

  const goToNextMonth = () => {
    setSelectedDay((prevDay) =>
      moment(prevDay).add(1, 'month').format('YYYY-MM-DD')
    );
  };

  const goToPrevMonth = () => {
    setSelectedDay((prevDay) =>
      moment(prevDay).subtract(1, 'month').format('YYYY-MM-DD')
    );
  };

  const handleTodayPress = () => {
    goToToday();
    if (selectedDay === moment().format('YYYY-MM-DD')) {
      setActiveTab(1);
    }
  };

  const handleSelectDay = (day) => {
    setSelectedDay(moment(day).format('YYYY-MM-DD'));
    if (day === selectedDay) {
      setActiveTab(2);
    }
  };

  const renderDayNames = () => (
    <div className="dayNamesContainer">
      {days.map((dayName, index) => (
        <span key={index} className="dayName">
          {dayName}
        </span>
      ))}
    </div>
  );

  if (isLoading) {
    return (
      <div className="loader-full-screen">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {message && (
        <Alert message={message} setMessage={setMessage} type="error" />
      )}
      <div className="calendarContainer">
        <div>
          <div className="calendarHeader">
            <button className="calendarHeaderButton">
              {/* <FaFilter size={30} color="black" cursor="pointer" /> */}
            </button>
            <button onClick={goToPrevMonth} className="calendarHeaderButton">
              <FaChevronLeft size={40} color="black" cursor="pointer" />
            </button>
            <span className="calendarHeaderTitle">
              {months[selectedMoment.format('M') - 1]}{' '}
              {selectedMoment.format('YYYY')}
            </span>
            <button onClick={goToNextMonth} className="calendarHeaderButton">
              <FaChevronRight size={40} color="black" cursor="pointer" />
            </button>
            <button onClick={handleTodayPress} className="calendarHeaderButton">
              <MdToday size={30} color="black" cursor="pointer" />
            </button>
          </div>
          {renderDayNames()}
          <div className="monthGrid">
            {paddedDays.map((day, index) => {
              const dayKey = `${selectedMoment.format('YYYY-MM')}-${
                day < 10 ? `0${day}` : day
              }`;
              const isSelectedDay = dayKey === selectedDay;
              const isToday = dayKey === moment().format('YYYY-MM-DD');
              return (
                <button
                  key={index}
                  className="dayContainer"
                  onClick={() => handleSelectDay(dayKey)}
                  disabled={day === null}
                >
                  <div
                    className={`dayContent ${
                      isSelectedDay ? 'selectedDay' : ''
                    }`}
                    style={{
                      border: day === null ? 'none' : '',
                    }}
                  >
                    <div className={`day ${isToday ? 'today' : ''}`}>
                      <span className={`dayText ${isToday ? 'todayText' : ''}`}>
                        {day}
                      </span>
                    </div>
                    {day && (
                      <div style={{ width: '100%' }}>
                        {interventions?.filter(
                          (el) =>
                            el.date ===
                            moment(selectedDay).format(
                              `YYYY-MM-${day < 10 ? `0${day}` : day}`
                            )
                        ).length > 0 && (
                          <>
                            {(interventions?.filter(
                              (el) =>
                                el?.stabilisant < '50' || el?.stabilisant > '70'
                            ).length > 0 ||
                              interventions?.filter(
                                (el) => el?.ph < '6,9' || el?.ph > '7,7'
                              ).length > 0 ||
                              interventions?.filter(
                                (el) => el?.cholre < '2' || el?.cholre > '5'
                              ).length > 0) && (
                              <div
                                className="warning-badge"
                                style={{ top: '0.5rem', right: '1rem' }}
                              >
                                <RiErrorWarningFill size={20} color="red" />
                              </div>
                            )}
                            <div className="indicatorContainer">
                              <span
                                className="indicatorText"
                                style={{ color: gray }}
                              >
                                {
                                  interventions?.filter(
                                    (el) =>
                                      el.date ===
                                        moment(selectedDay).format(
                                          `YYYY-MM-${
                                            day < 10 ? `0${day}` : day
                                          }`
                                        ) && el?.state === undefined
                                  ).length
                                }
                              </span>
                              <div className="indicator" />
                            </div>
                            <div className="indicatorContainer">
                              <span
                                className="indicatorText"
                                style={{ color: gray }}
                              >
                                {
                                  interventions?.filter(
                                    (el) =>
                                      el.date ===
                                        moment(selectedDay).format(
                                          `YYYY-MM-${
                                            day < 10 ? `0${day}` : day
                                          }`
                                        ) && el?.state === 'doing'
                                  ).length
                                }
                              </span>
                              <div
                                className="indicator"
                                style={{
                                  backgroundColor: gray,
                                }}
                              />
                            </div>
                            <div className="indicatorContainer">
                              <span
                                className="indicatorText"
                                style={{ color: primaryColor }}
                              >
                                {
                                  interventions?.filter(
                                    (el) =>
                                      el.date ===
                                        moment(selectedDay).format(
                                          `YYYY-MM-${
                                            day < 10 ? `0${day}` : day
                                          }`
                                        ) && el?.state === 'done'
                                  ).length
                                }
                              </span>
                              <div
                                className="indicator"
                                style={{
                                  backgroundColor: primaryColor,
                                  borderColor: primaryColor,
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
        <div className="footer">
          <span className="footerText">
            Total {months[selectedMoment.format('M') - 1]}{' '}
            {selectedMoment.format('YYYY')} :{' '}
          </span>
          <span className="footerLabel">{interventions?.length}</span>
        </div>
      </div>
    </>
  );
};

export default MonthView;
