import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import Tabs from '../../components/generic/Tabs';
import GeneralTab from './GeneralTab';
import PoolsTab from './pool/PoolsTab';

const tabs = [
    'Génerale',
    'Picines'
];

const ClientProfile = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <div>
                <div className='navigation-title'>
                    <MdChevronLeft size={'3rem'} className='clickable' onClick={() => navigate(-1)} />
                    <h1>{location.state?.name ?? ''}</h1>
                </div>
                <Tabs items={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div className='page-wrapper'>
                {activeTab === 0 && <GeneralTab id={id} />}
                {activeTab === 1 && <PoolsTab id={id} />}
            </div>
        </>
    );
};

export default ClientProfile;